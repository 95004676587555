
export class SetReportRq {
    "address": string;
    "description": string;
    "quoteNumber": string;
    "quotePolicyNumber": string;
    "isBind": boolean;
    "Name": string;
    "Email": string;
    "EffectiveDate": string;
    "PhoneNumber": string;

    constructor(
        address: string = "",
        description: string = "",
        quoteNumber: string = "",
        quotePolicyNumber: string = "",
        isBind: boolean = false,
        Name: string = "",
        Email: string = "",
        EffectiveDate: string = "",
        PhoneNumber: string = "",
    ) {
        this.address = address;
        this.description = description;
        this.quoteNumber = quoteNumber;
        this.quotePolicyNumber = quotePolicyNumber;
        this.isBind = isBind;
        this.Name = Name;
        this.Email = Email;
        this.EffectiveDate = EffectiveDate;
        this.PhoneNumber = PhoneNumber;
    }
}