import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactsComponent } from './contacts/contacts.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { FAQComponent } from './faq/faq.component';
import { FraudComponent } from './fraud/fraud.component';
import { FloridaHomeownersComponent } from './florida-homeowners/florida-homeowners.component';
import { TexasHomeownersComponent } from './texas-homeowners/texas-homeowners.component';
import { PetInsuranceComponent } from './pet-insurance/pet-insurance.component';
import { FloodInsuranceComponent } from './flood-insurance/flood-insurance.component';
import { AutoInsuranceComponent } from './auto-insurance/auto-insurance.component';
import { HomeDiscountsComponent } from './home-discounts/home-discounts.component';
import { BlogInsuranceComponent } from './blog-insurance/blog-insurance.component';
import { LifeInsuranceComponent } from './life-insurance/life-insurance.component';
import { CreditRepairComponent } from './credit-repair/credit-repair.component';
import { SickpayPlusComponent } from './sickpay-plus/sickpay-plus.component';
import { HighvalueHomesComponent } from './highvalue-homes/highvalue-homes.component';
import { TheftIdComponent } from './theft-id/theft-id.component';
import { HomeApplianceComponent } from './home-appliance/home-appliance.component';
import { AutoTurboraterComponent } from './auto-turborater/auto-turborater.component';
import { AccidentDeathComponent } from './accident-death/accident-death.component';
import { SelfInspectionComponent } from './self-inspection/self-inspection.component';
import { LossPreventionComponent } from './loss-prevention/loss-prevention.component';
import { PolicyChangeComponent } from './policy-change/policy-change.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';
import { InstantHomePolicyComponent } from './instant-home-policy/instant-home-policy.component';
import { PaymentProcessAEGComponent } from './payment-process-aeg/payment-process.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'faq', component: FAQComponent },
  { path: 'pet-insurance', component: PetInsuranceComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'florida-homeowners', component: FloridaHomeownersComponent },
  { path: 'texas-homeowners', component: TexasHomeownersComponent },
  { path: 'flood-insurance', component: FloodInsuranceComponent },
  { path: 'auto-insurance', component: AutoInsuranceComponent },
  { path: 'home-discounts', component: HomeDiscountsComponent },
  { path: 'blog-insurance', component: BlogInsuranceComponent },
  { path: 'life-insurance', component: LifeInsuranceComponent },
  { path: 'credit-repair', component: CreditRepairComponent },
  { path: 'sickpay-plus', component: SickpayPlusComponent },
  { path: 'highvalue-homes', component: HighvalueHomesComponent },
  { path: 'theft-id', component: TheftIdComponent },
  { path: 'home-appliance', component: HomeApplianceComponent },
  { path: 'auto-turborater', component: AutoTurboraterComponent },
  { path: 'accident-death', component: AccidentDeathComponent },
  { path: 'self-inspection', component: SelfInspectionComponent },
  { path: 'loss-prevention', component: LossPreventionComponent },
  { path: 'policy-change', component: PolicyChangeComponent },
  { path: 'get-quote', component: GetQuoteComponent },
  { path: 'instant-home-policy', component: InstantHomePolicyComponent },
  { path: 'paymentProcessAeg', component: PaymentProcessAEGComponent },
  { path: 'payment-success', component: PaymentSuccessComponent },
  {
    path: 'quote',
    loadChildren: () =>
      import('./modules/steps/steps.module').then((m) => m.StepsModule),
  },
  { path: "**", component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
