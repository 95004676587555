import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flood-insurance',
  templateUrl: './flood-insurance.component.html',
  styleUrls: ['./flood-insurance.component.scss']
})
export class FloodInsuranceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
