<app-header></app-header>
<section class="nsurehub-section" id="contact-header">
  <h2>Make the Move with NsureHub today!</h2>
</section>

<section class="nsurehub-section" id="contact-content">
  <div class="contentImgBlock">
    <img src="../../assets/images/nsurehub/contactIcon.png" alt="NsureHub"/>
    <h3>Contact Info</h3>
  </div>
  <div class="contactInfoBlock">
    <h4>We make it just as easy to purchase with<br> one of our trusted advisors.</h4>
    <p>Have questions or need to speak about other products? We make it super easy.</p>
    <p>For help with a quote, policy, service, or anything else, give us a call or chat with us during hours of
      operation or leave your info in our chat,
      and we'll be in touch. </p>
    <div class="conyactDetails">
      <h2>Call: 1-888-678-7266</h2>
      <p class="hoursofoperation">Hours of operation</p>
      <p class="hourstimings">Monday - Friday: 9am-6pm</p>
    </div>
  </div>
</section>
<app-footer></app-footer>