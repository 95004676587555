<div class="center_img">
    <img (click)="closePopup()" src="../../../assets/images/Vector.png" alt="NsureHub">
  </div>
<div class="benefitChartContainer">
    <h2>The difference between Buying online and Calling in.</h2>

    <div class="benefitsList">
        <div class="benefit benifitLabels">
            <h3>Benefits</h3>
            <ul>
                <li>Price</li>
                <li>Time</li>
                <li>Application Process</li>
                <li>Home Inspection</li>
                <li>Pay for Home Inspection</li>
                <li>Inspection Credit From Us</li>
                <li>Quotes</li>

            </ul>
        </div>
        <div class="benefit benifitItem">
            <img src="../../assets/images/nsurehub/globe-benefit.png" alt="NsureHub"/>
            <div class="benefitBox">
                <h3>Purchase Online</h3>
                <ul>
                    <li>Higher</li>
                    <li>Fast and Easy</li>
                    <li>None</li>
                    <li>None</li>
                    <li>No Inspection no cost</li>
                    <li>None since not required</li>
                    <li>Only one company</li>

                </ul>
               
            </div>
            <button class="button-fill" (click)="closePopup1()">Get a Quote</button>

        </div>
        <div class="benefit benifitItem">
            <img style="width:30px" src="../../assets/images/nsurehub/call-benefit.png" alt="NsureHub"/>
            <div class="benefitBox">
                <h3>Call Agent</h3>
                <ul>
                    <li>Cheaper</li>
                    <li>Takes more time to buy if you call in</li>
                    <li>Have to fill out a long application form</li>
                    <li>Required</li>
                    <li>Could cost customer up to $400</li>
                    <li>$50</li>
                    <li>Several companies pricing</li>

                </ul>
                
            </div>
            <a href="tel:+18444032444" class="button-fill">Call in</a>
        </div>
    </div>
</div>