import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loss-prevention',
  templateUrl: './loss-prevention.component.html',
  styleUrls: ['./loss-prevention.component.scss']
})
export class LossPreventionComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
  }

}
