<app-header></app-header>
<section class="nsurehub-section" id="quote-section">
    <div class="quoteBlock">
        <h1>Get your home <i>insurance</i> fast and quick with just a few clicks</h1>
        <div class="address-input">
            <input type="text" id="pac-input" (keyup)="autocompleteAddress()"
                placeholder="Enter your primary single-family home address to get a quote"
                aria-label="Enter your home address">
            <mat-form-field class="full-width" style="width: 80%;" appearance="outline" id="writingCompany"
                [hidden]="this.clientAddress == undefined || this.clientAddress.state != 'FL'" *ngIf="false">
                <mat-label>Writing Company </mat-label>
                <mat-select [(ngModel)]="writingCompany" name="writingCompany">
                    <mat-option value="">Select Option</mat-option>
                    <mat-option value="Carrier">Safepoint</mat-option>
                    <mat-option value="Manatee">Manatee</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="quoteTermsPolicyBlock">
                <input type="checkbox" (change)="checkboxChanges($event)" aria-label="check">
                <div class="termsText">
                    <span>I accept NsureHub's terms and conditions. <label *ngIf="!showRemainingTC"
                            (click)="showRemainingTC = !showRemainingTC" class="seemoreLabel">See
                            more...</label><span class="remainingTC" *ngIf="showRemainingTC">* By accepting the terms
                            and conditions, I acknowledge that the information I provide will be used to underwrite, and
                            rate my policy, and that NsureHub will obtain and share my personal information with
                            consumer reporting agencies and other third parties in order to provide me the most accurate
                            quote. I also understand that NsureHub may send me additional communications regarding
                            NsureHub services.For additional details on how we use your personal
                            information:</span><label *ngIf="showRemainingTC"
                            (click)="showRemainingTC = !showRemainingTC" class="seemoreLabel">See
                            less...</label></span>
                    <span> Please review our Privacy Policy: NsureHub <b (click)="openPrivacyPolicy();">Privacy
                            Policy</b> and <b (click)="openTermsPopup();">Terms of
                            Use</b></span>
                </div>
            </div>
            <p class="mandatoryLabel">*Please check the box and hit Start My Quote...</p>
        </div>
        <div class="startMyQuoteBlock">
            <button class="button-fill"
                [disabled]="!termsChecked || !isAddressFilled || (clientAddress.state == 'FL' && !writingCompany)"
                (click)="startQuote()">Start My
                Quote</button>
            <img (click)="openhowtoVideosPopup()" src="../../assets/images/nsurehub/howto.png" alt="NsureHub" />
        </div>
    </div>
    <button class="button-auto-quote" routerLink="/auto-turborater">
        <!-- <img src="../../assets/images/header-icon/auto.png" alt="NsureHub" align="left"/> -->
        <i class='fas fa-car-side' style='font-size:44px;' align="left"></i>
        CLICK FOR INSTANT AUTO QUOTES IN TWO MINUTES
    </button>
    <!-- <button ></button> -->
</section>
<br>
<section class="nsurehub-section" id="covered-features">
    <div class="coveredCTA">
        <div class="row">
            <div class="col-sm-4 ctaBox">
                <p style="padding: 0px 36px;">Buy online now and save time and money; no inspection or application is
                    required. Your policy is
                    issued instantly</p>
                <button class="button-fill"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a Quote </button>
            </div>
            <div class="col-sm-3 ctaBox">
                <h2>OR</h2>
                <label class="promtionhyperlink" (click)="openPromotionPopup();">Promotion T&C</label>
            </div>
            <div class="col-sm-5 ctaBox">
                <p>Lower policy premium, but aDemo complete application and home inspection are required. Home
                    inspection
                    costs could be up to $400 with a 15-day application processing time.</p>
                <div class="space-30"></div>
                <button class="button-fill"><a href="tel:+18444032444">Call in</a></button>
            </div>
        </div>
    </div>
    <br>
    <h2>Manage the risks of everyday life with confidence. <br>We've got you covered!</h2>
    <div class="featuresList">
        <div class="feature">
            <img src="../../assets/images/nsurehub/simpleandeasy.png" alt="NsureHub" title="Get a Quote" />
            <h4>Simple and Easy</h4>
            <p>We make the insurance quoting, purchasing, and claiming process stress and hassle-free.</p>
        </div>
        <div class="feature">
            <img src="../../assets/images/nsurehub/high-savings.png" alt="NsureHub" title="Get a Quote" />
            <h4>High Savings</h4>
            <p>With our affordable insurance plans and low costs, you get to make the most of your hard-earned dollars.
            </p>
        </div>
        <div class="feature">
            <img src="../../assets/images/nsurehub/superfast.png" alt="NsureHub" title="Get a Quote" />
            <h4>Super Fast</h4>
            <p>Time is of the essence. We understand that. This is why we offer the fastest sales platform in the
                industry.
            </p>
        </div>
        <div class="feature">
            <img src="../../assets/images/nsurehub/personalized.png" alt="NsureHub" title="Get a Quote" />
            <h4>Personalized</h4>
            <p>You are unique with your own set of needs and budget. So are our insurance policies for you.</p>
        </div>
        <div class="feature">
            <img src="../../assets/images/nsurehub/teamcare.png" alt="NsureHub" title="Get a Quote" />
            <h4>Team that Cares</h4>
            <p>Your time and happiness matters to us. We look out for you!
            </p>
        </div>
        <div class="feature">
            <img src="../../assets/images/nsurehub/support.png" alt="NsureHub" title="Get a Quote" />
            <h4>Support 24/7/365</h4>
            <p>Our team is only a click and call away – available around the clock to serve you.
            </p>
        </div>
    </div>
    <div class="featureCTAButtonContainer">
        <button class="button-fill"
            onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
            title="Get a Quote" *ngIf="false">Get a
            Quote
        </button>
    </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
    <div class="benefitChartCTABlock">
        <h2>Buying on site Vs Calling in</h2> <button class="button-fill" (click)="openbenefitChart();">Benefit
            Chart</button>
    </div>

</section>
<section class="nsurehub-section" id="testimonial-section">
    <app-testimonials></app-testimonials>

</section>
<section class="nsurehub-section" id="insuranceNeed-features">
    <div class="coveredCTAF">
        <p>Talk to our agents about your insurance needs! <br> 1-888-678-7266</p>
    </div>
    <h2>All your insurance needs handled!</h2>
    <div class="insurancefeaturesList">
        <div class="insurancefeature">
            <h4>SMALL COMMERCIAL</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/smallCom.png" alt="NsureHub" />
                <p>Your business, your livelihood. We keep it safe, so you can focus on growth.</p>
                <div class="space-0"></div>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <div class="insurancefeature">
            <h4>Auto</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/Auto.png" alt="NsureHub" />
                <p>The coverage you need when you're on the road.</p>
                <div class="space-30"></div>
                <button class="button-fill_1" routerLink="/auto-turborater" title="Get a Quote" *ngIf="false">Get a
                    Quote</button>
            </div>
        </div>
        <div class="insurancefeature" *ngIf="false">
            <h4>Flood</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/Flood.png" alt="NsureHub" />
                <p>Stay secure even when the most frequent natural disaster comes calling.</p>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <div class="insurancefeature">
            <h4>Motorcycle</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/Motorcycle.png" alt="NsureHub" />
                <p>Insure your ride and enjoy the freedom of open road.</p>
                <br>
                <br>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <div class="insurancefeature">
            <h4>Boat</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/Boat.png" alt="NsureHub" />
                <p>Get sea-worthy coverage and complete peace of mind.</p>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <div class="insurancefeature">
            <h4>RV</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/RV.png" alt="NsureHub" />
                <p>Protect your home on wheels and go on adventures of a lifetime.</p>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <div class="insurancefeature">
            <h4>Golf Cart</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/GolfCart.png" alt="NsureHub" />
                <p>Protect your home on wheels and go on adventures of a lifetime.</p>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <div class="insurancefeature" *ngIf="false">
            <h4>Umbrella</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/Umbrella.png" alt="NsureHub" />
                <p>Get extra coverage for the unexpected and unplanned</p>
                <div class="space-30"></div>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <!-- <div class="insurancefeature">
            <h4>Pet</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/Pet.png" alt="NsureHub" />
                <p>Make sure your beloved furry friend stays happy and healthy.</p>
                <button class="button-fill_1"
                    onclick="window.location.href='https://figopetinsurance.com/?p=9e9T0V7O7s7#get-a-quote/';"
                    title="Pet" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div> -->
        <div class="insurancefeature">
            <h4>Home</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/nsurehub/Home.png" alt="NsureHub" />
                <p>Safeguard what matters most to you - your home!</p>
                <div class="space-30"></div>
                <button class="button-fill_1"
                    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
                    title="Get a Quote" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <div class="insurancefeature">
            <h4>Life</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/icons/Home/life.png" alt="NsureHub" />
                <p>No medical exams means all you have to do is answer a few health questions</p>
                <div class="space-30"></div>
                <button class="button-fill_1"
                    onclick="window.location.href='https://www.ladderlife.com/apply/quick-start?utm_source=nsurehub';"
                    title="Life" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div>
        <!-- <div class="insurancefeature">
            <h4>Travel</h4>
            <div class="insurancefeatureBox">
                <img src="../../assets/images/icons/Home/travel.png" alt="NsureHub" />
                <p>Specially Negotiated Hotel and Rental Rates for <br>Customers</p>
                <div class="space-30"></div>
                <button class="button-fill_1" onclick="window.location.href='https://nsurehub.hotelplanner.com/';"
                    title="Travel" *ngIf="false">Get a
                    Quote
                </button>
            </div>
        </div> -->
    </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section" *ngIf="false">
    <div class="benefitChartCTABlock">
        <h2>Get you a quote now! </h2>
        <button class="button-fill"
            onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
            title="Get a Quote">Get a
            Quote
        </button>
    </div>
</section>
<section class="nsurehub-section" id="partners-section" *ngIf="false">
    <div class="partnweBestBlock">
        <h2>NsureHub Has Partnered With The Best</h2>
        <p>SafePoint is proud to be rated favorably by leading independent rating organizations. These companies
            specialize in reviewing, analyzing and attesting to insure companies’ financial security and their ability
            to deliver on promises made to policyholders.</p>
        <h4>Demotech Rating</h4>
        <p><i>Demotech has assigned the rating of A Exceptional to SafePoint</i></p>
        <p>This Financial stability rating is an indication of an insurance company’s financial strength.</p>
        <div class="partnweBestBlockSec">
            <p>According to Demotech, an A rating means Safepoint has an Exceptional ability to maintain liquidity of
                invested assets, quality reinsurance, acceptable financial leverage and realistic pricing while
                simultaneously establishing loss and loss adjustment expense reserves at reasonable levels.</p>
            <img src="../../assets/images/nsurehub/exceptionalNsurehub.png" alt="NsureHub" />
        </div>
        <p>For details, <span class="visitLink"><a target="_blank" href="https://www.demotech.com/">Visit Demotech
                    on-line here.</a></span></p>
        <div class="partnersList">
            <img src="../../assets/images/nsurehub/aggne.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/Figo.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/Progressive.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/verisk-logo1.svg" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/coherent.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/aws.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/gic.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/Jetsons-Living-Logo-1.webp" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/gaju.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/safepoint.png" alt="NsureHub" />
            <img src="../../assets/images/nsurehub/guardian.png" alt="NsureHub" />
        </div>
    </div>
</section>

<section class="nsurehub-section" id="footer-imagesection">
    <div class="footer-imagesectionContent">
        <h4>Fulfill your responsibility to yourself & your loved ones.</h4>
        <br>
        <section class="nsurehub-section" id="about-video">
            <video controls poster>
                <source src="../../assets/videos/NsureHub.mp4" type="video/mp4">
                Your browser does not support the video tag.
                <track label="English" kind="subtitles" srclang="en" src="REV_TRANSCRIPTION_V3.vtt" default>
            </video>
        </section>
        <br>
        <h4>How does NsureHub work</h4>
    </div>
</section>
<app-footer></app-footer>