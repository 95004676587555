<app-header></app-header>
<section class="nsurehub-section" id="fl-ho-main">
  <div class="fl-ho-main_content">
    <h1>NsureHub Identity Theft Expense And Resolution Services</h1>
    <p>We will pay up to $25,000 for expenses incurred by an insured as the direct result of any one identity theft
      discovered during the policy period. Additionally, you will have access to resolution services from a consumer
      fraud specialist who will assist you in the process of restoring your identity.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="cybercrime">
  <h2>What is identity theft and cybercrime?</h2>
  <p>Identity theft is the act of stealing any combination of personally identifiable information, such as an address,
    birthdate, or Social Security number, usually with the intention of assuming someone else’s identity or reselling
    the stolen data for financial benefit. Identity theft can happen as a result of being a victim of a cybercrime.</p>
  <p><b>Cybercrime</b> is a criminal activity that either targets or uses a computer, a computer network, or a networked
    device.
    Most, but not all cybercrime is committed by cybercriminals or hackers who want to make money - Cybercrime may be
    carried out by individuals or organizations. Some cybercriminals may be organized, use advanced techniques and are
    highly technically skilled. Others may be novice hackers
  </p>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="coverage-options">
  <h2>Common coverage options</h2>
  <p>Below is a list of common coverage options typically associated with Identity theft insurance:</p>
  <div class="coverageTypes">
    <div class="addcoverageType">
      <h3>Lost wallet protection</h3>
      <p>Few situations are more unnerving and inconvenient than losing your wallet. The plethora of cards to cancel and
        documents to replace can be overwhelming – if you can even remember all that you had. With lost wallet
        protection, your insurance provider may do most of the legwork for you: canceling credit and debit cards,
        requesting replacements and helping you obtain a new driver’s license and medical insurance cards.
      </p>
      <p>When you enroll, you’ll register as many cards as you like, so your information is already on file should you
        need to call in a lost or stolen wallet. With some plans, you can get help in replacing passports, keys, and
        mobile devices as well. Others may offer a cash advance if certain conditions are met, such as being a given
        distance away from home.</p>
    </div>
  </div>
  <div class="coverageTypes">
    <div class="addcoverageType">
      <h3>Alert system</h3>
      <p>Numerous companies use alert systems to notify you via text, phone, or email that your information has been
        compromised. Often these alerts will outline step-by-step how to resolve the suspected theft in tandem with
        their efforts. Before selecting a membership plan for these services, you’ll want to learn what alerts are
        offered and any steps you must take to activate them, such as registering all your card information online.</p>
    </div>
  </div>
  <div class="coverageTypes">
    <div class="addcoverageType">
      <h3>Address change notification</h3>
      <p>Moving to a new home, apartment, or condo – either permanently or temporarily – is really the only time you
        need to update your address with the U.S. Postal Service. Identity thieves, though, may have a different agenda
        in mind. By submitting address change requests, criminals can attempt to redirect your mail and steal your bank
        statements, medical bills, and other important documents. With address change verification, you’ll be alerted of
        any address changes submitted on your behalf without your authorization.</p>
    </div>
  </div>
  <div class="coverageTypes">
    <div class="addcoverageType">
      <h3>Black market website surveillance</h3>
      <p>If your personal identity is stolen, the thievery doesn’t necessarily stop right there: criminals can then sell
        your information on underground websites to other thieves. Black market website surveillance inspects thousands
        of websites, chat rooms and other databases for illicit use of your personal information. If bought, sold,
        traded or otherwise compromised, you’ll receive an alert outlining what you need to do next, including changing
        passwords, freezing your bank accounts and closely monitoring your financial information.</p>
    </div>
  </div>
  <div class="coverageTypes">
    <div class="addcoverageType">
      <h3>Credit monitoring</h3>
      <p>You are able to check your credit score with the free report available from major credit reporting agencies
        once a year. If you would like to monitor your credit more regularly, another option is to register online at a
        handful of websites – like Credit Karma - to track your credit score for free. Often you can sign up to receive
        alerts via email, or you can log in at any time to see your updated score. Credit monitoring allows you to
        recognize any unexpected dips in your credit score, which could indicate that your financial information has
        fallen into the wrong hands. More advanced credit monitoring – such as a compilation of credit reports from
        three major credit bureaus – is available at an additional cost.</p>
    </div>
  </div>
  <div class="coverageTypes">
    <div class="addcoverageType">
      <h3>Credit card, checking and savings activity alerts</h3>
      <p>Some identity theft protection companies offer notifications for cash withdrawals, balance transfers and large
        purchases, all in one place – but for a price. You may just as easily be able to monitor your own accounts for
        free by signing up for alerts or mobile banking directly through your bank.</p>
    </div>
  </div>
  <div class="coverageTypes">
    <div class="addcoverageType">
      <h3>Public record monitoring</h3>
      <p>With some memberships, you can receive quarterly reports related to your publicly available information. These
        quarterly updates will inform you of any changes to your information or assure you that it’s consistent.</p>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="cybercrime">
  <h2>DEDUCTIBLE</h2>
  <p>No deductible applies to identity theft coverage.</p>
</section>
<section class="nsurehub-section" id="identity-theft">
  <div class="visionBox">
    <p><b>“Identity theft”</b> means the act of knowingly transferring or using, without lawful authority, a means of
      identification of an insured with the intent to commit, or to aid or abet, any unlawful activity that constitutes
      a violation of federal law or a felony under any applicable state or local law.
    <p>
    <p><b>“Resolution services”</b> provides a personal advocate assigned to victim who works one-one, from first call
      to
      crisis
      resolution. It also includes ordering your credit report, alerting credit reporting agencies, providing credit and
      fraud monitoring, (credit monitoring and fraud monitoring are only available to insured’s with online access to
      the internet and valid email addresses) and preparing necessary documentation and letters.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="theft-support">
  <h2>IDENTITY THEFT SUPPORT SERVICES</h2>
  <div class="coreValuesList">
    <div class="coreValue">
      <h1>Police Report</h1>
      <h6>Provide assistance with filing a Police Report, if direction or discussion with law enforcement regarding the
        need and use of report is requested.
      </h6>
    </div>
    <div class="coreValue">
      <h1>Document Recovery/Lost Wallet Assistance</h1>
      <h6>If you lose your wallet or other sensitive documents we will help you protect yourself by guiding you in the
        recovery and /or reissuance of “breeder documents,” such as driver’s license, passport or Social Security card.
      </h6>
    </div>
    <div class="coreValue">
      <h1>Fraud Alert Assistance</h1>
      <h6>Provide assistance and guidance in placing fraud alerts on credit reports when appropriate and as provided by
        state and federal law.
      </h6>
    </div>
    <div class="coreValue">
      <h1>Credit Freeze</h1>
      <h6>Provide assistance and guidance in placing credit security freezes on credit reports when appropriate and as
        provide by state or federal laws.
      </h6>
    </div>
    <div class="coreValue">
      <h1>Credit Report</h1>
      <h6>Provide assistance in reviewing credit reports to determine accuracy of the files and detect potential areas
        of fraud.
      </h6>
    </div>
    <div class="coreValue">
      <h1>Free Credit Report Reminder Service</h1>
      <h6>Provide assistance and guidance to access free annual credit reports.
      </h6>
    </div>
    <div class="coreValue" style="margin-left: 30%;">
      <h1>Postal Junk Mail Reduction Service</h1>
      <h6>We will help you remove your name from some postal junk mail lists and information databases so you receive
        less postal junk mail.
      </h6>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="exclusions">
  <div class="visionBox">
    <h3>EXCLUSIONS</h3>
    <p>The following additional exclusions apply to this coverage.<br>
      We do not cover:
    </p>
    <ol>
      <li>The following additional exclusions apply to this coverage.</li>
      <li>Expenses incurred due to any fraudulent, dishonest or criminal act by an insured or any person acting in
        concert
        with an insured, or by any authorized representative of an insured, whether acting alone or in collusion with
        others.
      </li>
      <li>Loss other than expenses or resolution services.</li>
    </ol>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="theft-definition">
  <h2>DEFINITIONS</h2>
  <p>With respect to the provisions of this endorsement only, the following definitions are added:</p>
  <div class="theft-definitionTypes">
    <div class="addType">
      <ol>
        <li>
          Costs for notarizing fraud affidavits or similar documents for financial institutions or similar credit
          grantors or credit agencies that have required that such affidavits be notarized.
        </li>
        <li>Costs for certified mail to law enforcement agencies, credit agencies, financial institutions or similar
          credit grantors.
        </li>
        <li>Lost wages as a result of time taken off from work to meet with, or talk to, law enforcement agencies,
          credit
          agencies and/or legal counsel, or to complete fraud affidavits, or due to wrongful incarceration arising
          solely from someone else having committed a crime in the insured's name, up to a maximum payment of $1000 per
          week for a maximum period of five weeks.
        </li>
        <li>Loan application fees for re-applying for a loan or loans when the original application is rejected solely
          because the lender received incorrect credit information.
        </li>
        <li>Costs for daycare and eldercare incurred by an insured solely as a direct result of any one identity theft.
        </li>
      </ol>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="theft-footer">
  <div class="theft-footerContent">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>

<app-footer></app-footer>