import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-news-and-partners',
  templateUrl: './news-and-partners.component.html',
  styleUrls: ['./news-and-partners.component.scss']
})
export class NewsAndPartnersComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<NewsAndPartnersComponent>) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close(); 
  }
}
