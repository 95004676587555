<div class="policyDetailsContainer"
    [ngClass]="(isSelectionEnabled && appService.selectedPackage == type) ? 'highlightPackage' : ''">
    <h2 [ngStyle]="{'margin-bottom' : isSelectionEnabled ? '0px': ''}">Custom Policy Just for you <br /> {{type}}
        Package</h2>
    <div class="policyData">
        <div class="policyPremiumContent" *ngIf="!isSelectionEnabled">
            <h3>{{quoteType=='final'?'Final Annual Premium':'Total Premium Indication'}}</h3>
            <h1>{{totalPremium |currency:'USD':'symbol':'3.0'}}</h1>
        </div>
        <div class="policyOtherDetails" [class.roundBorder]="quoteType === 'final'" [hidden]="!coverageDetails"
            [ngStyle]="{'border-radius': isSelectionEnabled ? '0 0 15px 15px' : '50px 50px 35px 35px'}">
            <div class="policyDetailsItems">
                <ul class="quoteDetailsContainer">
                    <li title="Total cost to rebuild or repair your home.">
                        <label class="quoteDetailLabel"> Your Home Rebuild<span class="quoteDetailsColons">:</span>
                        </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.yourHomeRebuild)}}</span>
                    </li>
                    <li title="All your contents and the cost to replace or repair them.">
                        <label class="quoteDetailLabel"> Your Belongings<span class="quoteDetailsColons">:</span>
                        </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.yourBelongings)}}</span>
                    </li>
                    <li
                        title="Additional property on your land, such as a fence shed gazebo, and the cost to replace or repair them.">
                        <label class="quoteDetailLabel"> Other Structures<span class="quoteDetailsColons">:</span>
                        </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.otherStructures)}}</span>
                    </li>
                    <li title="Amount you're responsible for, before your insurance kicks in.">
                        <label class="quoteDetailLabel"> Hurricane Deductible<span class="quoteDetailsColons">:</span>
                        </label>
                        <span
                            class="quoteDetailValue">{{getRespectiveValue(coverageDetails.hurricaneDeductible)}}</span>
                    </li>
                    <li *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX'">
                        <label class="quoteDetailLabel"> Wind or Hail Deductible<span
                                class="quoteDetailsColons">:</span> </label>
                        <span class="quoteDetailValue"
                            *ngIf="coverageDetails.windorHailDeductible == null">{{coverageDetails.windorHailDeductible}}</span>
                        <span class="quoteDetailValue"
                            *ngIf="coverageDetails.windorHailDeductible != null">{{coverageDetails.windorHailDeductible*100}}%</span>
                    </li>
                    <li title="Non-Hurricane Deducible are the set amount you pay before your insurance kicks in.">
                        <label class="quoteDetailLabel"> All Other Losses Deductible<span
                                class="quoteDetailsColons">:</span>
                        </label>
                        <span class="quoteDetailValue"
                            *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='FL' || this.appService.DCJSON.AccountDetails.PropertyAddress.State=='LA'">{{getRespectiveValue(coverageDetails.allOtherLossesDeductible)}}</span>
                        <span class="quoteDetailValue"
                            *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX' || this.appService.DCJSON.AccountDetails.PropertyAddress.State=='AL'">{{coverageDetails.allOtherLossesDeductible*100}}%</span>

                    </li>
                    <li
                        title="If someone gets hurt, both at home or while you're aways, you could be sued and held responsible. Liability coverage can help cover the potential cost including potential legal fees.">
                        <label class="quoteDetailLabel"> Personal Liability<span class="quoteDetailsColons">:</span>
                        </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.personalLiability)}}</span>
                    </li>
                    <li
                        title="Sudden and Accidental loss due to water such a burst pipe or ruptured water heater. This limits the amount of coverage available to repair the damage from such water losses.">
                        <label class="quoteDetailLabel"> Water Loss Limit<span class="quoteDetailsColons">:</span>
                        </label>
                        <span class="quoteDetailValue">{{coverageDetails.waterLossLimit}}</span>
                    </li>
                </ul>
                <div class="seeMoreBtn">
                    <h3 (click)="showMorePolicyContent = !showMorePolicyContent">
                        {{showMorePolicyContent?'Less Details':'See Details'}}
                    </h3>
                </div>
                <ul class="quoteDetailsContainer" *ngIf="showMorePolicyContent">
                    <li title="Personal Injury protect you in the event of personal injury to others.">
                        <label class="quoteDetailLabel"> Personal Injury: </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.personalInjury)}}</span>
                    </li>
                    <li title="Medical Payments covers losses such as a guest that gets injured at your home.">
                        <label class="quoteDetailLabel"> Medical Payments: </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.medicalPayments)}}</span>
                    </li>
                    <li
                        title="If you must move out of your home after a covered loss, this will cover hotel rooms and dining out the cost.">
                        <label class="quoteDetailLabel"> Loss of Use: </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.lossOfUse)}}</span>
                    </li>
                    <li
                        title="The extra cost you could incur after a loss in order to bring your home up to code and regulations.">
                        <label class="quoteDetailLabel"> Ordinance or Law: </label>
                        <span class="quoteDetailValue" *ngIf="coverageDetails.ordinanceOrLaw =='0'">10%</span>
                        <span class="quoteDetailValue"
                            *ngIf="coverageDetails.ordinanceOrLaw !='0'">{{getRespectiveValue(coverageDetails.ordinanceOrLaw)}}</span>
                    </li>
                    <li
                        title="Coverage for damage to your property caused by a clogged sewer line, failed sump pump and backed up drains. It also covers mold damage to your home caused by water or sewer backup.">
                        <label class="quoteDetailLabel">
                            Water Backup/Sump Pump Overflow:
                        </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.waterBackup)}}</span>
                    </li>
                    <li
                        title="It helps protect you if you live in a shared community, like a condo or homeowners association (HOA), where you’re responsible for a portion of damage or loss in a common area.">
                        <label class="quoteDetailLabel"> Loss/HOA Assessment: </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.lossAssessment)}}</span>
                    </li>
                    <li title="Fungus, Mold or Rot. If you have it, you need to remove it. Repairs and testing are covered."
                        *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='FL'">
                        <label class="quoteDetailLabel"> Mold, Fungi, Dry Rot: </label>
                        <span class="quoteDetailValue"
                            *ngIf="getMoldFungiPrice(coverageDetails.moldFungi)=='single'">{{coverageDetails.moldFungi
                            |currency:'USD':'symbol':'3.0'}}</span>
                        <span class="quoteDetailValue"
                            *ngIf="getMoldFungiPrice(coverageDetails.moldFungi)!='single'">{{getMoldFungiPrice(coverageDetails.moldFungi)['mold']
                            |currency:'USD':'symbol':'3.0'}}/{{getMoldFungiPrice(coverageDetails.moldFungi)['fungi']
                            |currency:'USD':'symbol':'3.0'}}</span>

                    </li>
                    <li title="Fungus, Mold or Rot. If you have it, you need to remove it. Repairs and testing are covered."
                        *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX'">
                        <label class="quoteDetailLabel"> Limited Fungi or Microbes: </label>
                        <span class="quoteDetailValue" *ngIf="coverageDetails.moldFungi=='1'">Included</span>
                        <span class="quoteDetailValue" *ngIf="coverageDetails.moldFungi=='0'">Excluded</span>

                    </li>
                    <li
                        title="Personal Property Replacement Cost pays the dollar amount it would take to buy a new item at the time of a claim.">
                        <label class="quoteDetailLabel">
                            Personal Property Replacement Cost:
                        </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.personalProperty)}}</span>
                    </li>
                    <li title="Increased replacement cost extends your dwelling coverage by 20% of the cost to rebuild your home."
                        *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State != 'AL'">
                        <label class="quoteDetailLabel" *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='FL'">
                            Increased Replacement Cost of 20% on Dwelling:
                        </label>
                        <label class="quoteDetailLabel"
                            *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX' || this.appService.DCJSON.AccountDetails.PropertyAddress.State=='LA'">
                            Increased Replacement Cost on Dwelling:
                        </label>
                        <span class="quoteDetailValue">{{getRespectiveValue(coverageDetails.increasedReplacementCost)}}</span>
                    </li>
                    <li
                        title="Actual cash value (ACV) is the value of destroyed or damaged Roof at the time of loss after your deducible is applied then insurance kicks in. Click here for the full endorsement Roof ACV Endorsement.">
                        <label class="quoteDetailLabel">
                            Actual Cash Value on your Roof:
                        </label>
                        <span
                            class="quoteDetailValue">{{getRespectiveValue(coverageDetails.actualCashValue?coverageDetails.actualCashValue:'0')}}</span>
                    </li>
                </ul>
            </div>
            <div class="optionalCoveragesSection">
                <h1 class="optionalCoveragesSectionTitle">Optional Coverages</h1>
                <ul class="optionalCoveragesContainer">
                    <li style="display: flex;" [ngClass]="type"
                        *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='FL'" class="optionalDetail"
                        title="In the event of a hurricane this covers the loss to repair or replace your screen enclosure/carport after your hurricane deducible is applied.">
                        <label class="chkcontainer" [ngClass]="type">Add Screen Enclosure/Carport:
                            <input type="checkbox" *ngIf="type !='Basic'" [(ngModel)]="screenEnclosureChecked"
                                (change)="applyScreenEnclosure('toggle', $event)">
                            <span *ngIf="type !='Basic'" class="checkmark"></span>
                            <span *ngIf="type =='Basic'" class="screenEnclosurePriceCls bold">Excluded</span>
                        </label>
                        <span *ngIf="type !='Basic'" class="screenEnclosurePriceCls bold"
                            (click)="showPriceSelector = !showPriceSelector">{{localDCResponse.RiskDetails.CarportsPoolCagesLimit
                            |currency:'USD':'symbol':'1.0'}}</span>
                        <div [class.checkoutPageSelector]="quoteType === 'final'"
                            class="policyDetailsContainer screenEnclosurePriceSelection" *ngIf="showPriceSelector">
                            <h4>Select Coverage Amount</h4>
                            <ul>

                                <li *ngFor="let price of listOfPriceSelector">
                                    <label class="chkcontainer">{{price |currency:'USD':'symbol':'3.0'}}
                                        <input *ngIf="type !='Basic'" type="checkbox"
                                            [checked]="price==screenClosurePrice"
                                            (click)="showPriceSelector = !showPriceSelector;screenClosurePrice=price;screenEnclosureChecked=false;applyScreenEnclosure('selector', $event)">
                                        <span *ngIf="type !='Basic'" class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>
                            <div class="leftArrowForPriceSelector"></div>
                        </div>
                    </li>
                    <li class="optionalDetail"
                        title="Animal liability coverage protects you in the event of damages or injuries caused by your dog or other pet that you own.">
                        <label class="chkcontainer" [ngClass]="type"
                            *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='FL'">
                            Add Animal Liability for $50:
                            <span *ngIf="type =='Basic'" class="screenEnclosurePriceCls bold">Excluded</span>
                            <span *ngIf="type !='Basic'" class="screenEnclosurePriceCls">{{animalLiabilityPrice}}</span>
                            <input *ngIf="type !='Basic'" type="checkbox" (change)="applyAnimalLiability($event)"
                                [(ngModel)]="animalLiabilityChecked" [disabled]="animalLiabilitydisabled">
                            <span *ngIf="type !='Basic'" class="checkmark"></span>
                        </label>
                        <label class="chkcontainer" [ngClass]="type"
                            *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX'">
                            Add Animal Liability for $25:
                            <span *ngIf="type =='Basic'" class="screenEnclosurePriceCls bold">Excluded</span>
                            <span *ngIf="type !='Basic'" class="screenEnclosurePriceCls">{{animalLiabilityPrice}}</span>
                            <input *ngIf="type !='Basic'" type="checkbox" (change)="applyAnimalLiability($event)"
                                [(ngModel)]="animalLiabilityChecked" [disabled]="animalLiabilitydisabled">
                            <span *ngIf="type !='Basic'" class="checkmark"></span>
                        </label>
                        <label class="chkcontainer" [ngClass]="type"
                            *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State == 'LA' || this.appService.DCJSON.AccountDetails.PropertyAddress.State=='AL'">
                            Add Animal Liability for $25:
                            <span *ngIf="type =='Basic'" class="screenEnclosurePriceCls bold">Excluded</span>
                            <span *ngIf="type !='Basic'" class="screenEnclosurePriceCls">{{animalLiabilityPrice}}</span>
                            <input *ngIf="type !='Basic'" type="checkbox" (change)="applyAnimalLiability($event)"
                                [(ngModel)]="animalLiabilityChecked" name="animalLiabilityChecked"
                                [disabled]="animalLiabilitydisabled">
                            <span *ngIf="type !='Basic'" class="checkmark"></span>
                        </label>
                    </li>
                    <li class="optionalDetail"
                        title="Identity theft is when thieves steal your personal information in order to take over or open new accounts, file fake tax returns, rent or buy properties, or do other criminal things in your name.">
                        <label class="chkcontainer" [ngClass]="type">
                            Add Identity Theft for $25:
                            <span *ngIf="type =='Basic'" class="screenEnclosurePriceCls bold">Excluded</span>
                            <span *ngIf="type !='Basic'" class="screenEnclosurePriceCls">
                                <span
                                    *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State == 'LA'">{{identityTheftPrice}}</span>
                                <span
                                    *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State != 'LA'">{{identityTheftChecked?'Included':''}}</span>
                            </span>
                            <input *ngIf="type !='Basic'" type="checkbox" [(ngModel)]="identityTheftChecked"
                                (change)="applyIdentityTheft($event)">
                            <span *ngIf="type !='Basic'" class="checkmark"></span>
                        </label>
                    </li>
                    <li class="optionalDetail"
                        title="Protect Personal Property and Vital Systems against equipment breakdown or systems failure. Appliances, Electrical systems, Garden tractors, Heating and cooling systems, Home electronics, Personal computers, Security systems, Swimming pool equipment, Water heaters, Well pumps and more.">
                        <label class="chkcontainer" [ngClass]="type">
                            Add Equipment Breakdown for $50:
                            <span *ngIf="type =='Basic'" class="screenEnclosurePriceCls bold">Excluded</span>
                            <span *ngIf="type !='Basic'" class="screenEnclosurePriceCls">
                                <span
                                    *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State == 'LA'">{{equipmentBreakdownPrice}}</span>
                                <span
                                    *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State != 'LA'">{{equipmentBreakdownChecked?'Included':''}}</span>
                            </span>
                            <input *ngIf="type !='Basic'" type="checkbox" [(ngModel)]="equipmentBreakdownChecked"
                                (change)="applyEquipmentBreakdown($event)">
                            <span *ngIf="type !='Basic'" class="checkmark"></span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="todayTotal" *ngIf="quoteType=='final'">
                <h3>Today's Total</h3>
                <h3>{{totalPremium |currency:'USD':'symbol':'3.0'}}</h3>
            </div>
            <div *ngIf="quoteType!='final'">
                <a class="sinkhole-btn" (click)="openSinkholeDialog()"
                    *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='FL'">
                    Sinkhole Coverage Exclusion
                </a>
                <a class="sinkhole-btn" (click)="openAnimalLibalityExclusionDialog()"
                    *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX'">
                    Animal Liability Exclusion
                </a>
                <a class="sinkhole-btn" (click)="openAnimalLibalityExclusionDialog()"
                    *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='LA'">
                    Animal Liability Endorsement
                </a>
            </div>
            <div *ngIf="quoteType=='final'">
                <a class="sinkhole-btn" *ngIf="this.appService.DCJSON.AccountDetails.PropertyAddress.State=='FL'"
                    (click)="showWinddtromPopup('Windstorm')">
                    Windstorm Mitigation Credits
                </a>
                <a class="sinkhole-btn"
                    *ngIf="(this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX' || this.appService.DCJSON.AccountDetails.PropertyAddress.State=='LA')"
                    (click)="showWinddtromPopup('Limited Liability')">
                    Limited Liability for Recreational Vehicles
                </a>
                <a class="sinkhole-btn"
                    *ngIf="(this.appService.DCJSON.AccountDetails.PropertyAddress.State=='TX' || this.appService.DCJSON.AccountDetails.PropertyAddress.State=='LA')"
                    (click)="showWinddtromPopup('Rental')">
                    Rental to Others Liability Exclusion
                </a>
            </div>
        </div>
    </div>
    <button class="button-fill" *ngIf="quoteType!='final' && !isSelectionEnabled" (click)="openDialog()"
        [ngStyle]="{'position': isSelectionEnabled ? 'relative': 'absolute'}">
        Select
    </button>
    <!-- <button class="button-fill" *ngIf="quoteType=='final' && type =='Basic'">
        Call to Purchase
    </button> -->
</div>