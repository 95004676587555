
export class TransactionRq {
    "TransactionId": string;
    "EmailId": string;
    "QuoteNumber": string;
    "Responsecode": string;
    "IsMortgage": string;
    "State": string;

    TransactionRq() {
        this.QuoteNumber = "";
        this.EmailId = "";
        this.TransactionId = "";
        this.Responsecode = "";
        this.IsMortgage = "";
        this.State = "";
    }
}