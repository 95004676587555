<app-header></app-header>
<section class="nsurehub-section" id="fl-ho-main">
  <div class="fl-ho-main_content">
    <h1>NsureHub's Accidental Death & Dismemberment (AD&D) is included with every policy you purchase!</h1>
    <p>
      AD&D Accidental Death
      <br>
      <br>
      NsureHub's Basic Accidental Death & Dismemberment insurance coverage can help individuals and family members
      maintain their lifestyles in the event an insured person is involved in a covered accident.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="active-policy">
  <h2>As long as you have an active policy inforce with NsureHub you recive the protection for you and your family the
    following:</h2>
  <div class="active-policycoverage">
    <div class="active-policycoverageType">
      <!-- <h3>Lost wallet protection</h3> -->
      <ol>
        <li>In the event of accidental death and dismemberment occurring before age 65, an additional amount of money
          over
          and above other policy benefits will be paid to you or your beneficiary
        </li>
        <li>Death or dismemberment of the insured should occur within 12 months from the date of accidental injury and
          the
          accidental injury is the direct and independent cause of death or dismemberment
        </li>
        <li>
          If the insured person is permanently disabled as a result of the accidental injury, a sum of $10,000
        </li>
      </ol>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Free with Purchase
    </button>
  </div>
</section>
<section class="nsurehub-section" id="exclusions">
  <div class="visionBox">
    <h3>Looking for affordable insurance protection that goes beyond your primary medical, long-term disability, and
      life insurance coverage?</h3>
    <p>
      For every NsureHub policy you purchase every customers under the age of 65, will get a $10,000 of Basic AD&D
      coverage at no cost to you, and all NsureHub customers that buy a policy from us.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2> Benefits for Having an Active Policy with NsureHub</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Free with Purchase
    </button>
  </div>
</section>
<section class="nsurehub-section" id="fl-ho-additionalCov_1">
  <h2>Free Accidental Death and Dismemberment (AD&D) Insurance</h2>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Accidental Death and Dismemberment (AD&D) Insurance</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Stand-alone mandatory or voluntary plans available.</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Comprehensive loss schedule and ancillary benefits.</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Valuable living benefits to help protect an individual's financial security in the event of a serious injury.
      </h3>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="ad-insurance">
  <h2>What does the NsureHub AD&D insurance cover?</h2>
  <p>The accidents and injuries on your AD&D coverage can pay depending on your policy, accidental dismemberment might
    include not only the loss of a limb but also serious accidental trauma that prevents you from working.
  </p>
  <div class="ad-insuranceTypes">
    <div class="ad-insuranceType">
      <h3>Accidents covered by AD&D insurance may include:</h3>
      <ol>
        <li>Car accidents</li>
        <li>Workplace injuries or death</li>
        <li>Fire-related injuries or fatality</li>
        <li>Accidents with firearms</li>
        <li>Falls and other injurious accidents</li>
      </ol>
    </div>
  </div>
  <div class="ad-insuranceTypes">
    <div class="ad-insuranceType">
      <h3>What is accidental death benefit (ADB) insurance?</h3>
      <p>Both AD&D and ADB life insurance policies only pay out in the case of accidents. The main difference is that an
        ADB policy may only pay out for a fatal accident, while AD&D policies can also pay out for accidental
        dismemberment and certain accidental injuries. Insurers might only offer one or the other, or both.
      </p>
    </div>
  </div>
</section>

<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Free with Purchase
    </button>
  </div>
</section>
<section class="nsurehub-section" id="accident-Covered">
  <div class="addaccidentTypes">
    <div class="addaccidentType">
      <h3>What’s Covered by AD&D Insurance?</h3>
      <p>AD&D insurance will cover only deaths and injuries from accidents—not natural causes or illnesses. Not heart
        attacks or strokes. Policies typically cover death or injuries from accidents at work, home and while traveling.
      </p>
      <p>In addition, if your death is the result of an accident while traveling on public transportation, including a
        bus, train or airplane, the payout can be double or triple the amount of your base coverage. To be covered, a
        death from an accident doesn’t have to be instant, but it usually needs to occur within a certain time frame,
        such as within a few months. The policy will outline the rules.
      </p>

      <h3>Are There Additional AD&D Insurance Benefits?</h3>
      <p>Some insurers will pay an additional benefit if you were wearing a seatbelt during an accident that led to an
        injury or death. Additional benefits might also be provided to help children pay for higher education.
        Counseling, legal and financial advising services for beneficiaries can be added benefits for a surviving
        spouse, too. These options will vary by insurer.
      </p>
      <h3>What’s Not Covered by AD&D Insurance?</h3>
      <p>What is considered an “accident” by insurance companies isn’t as straightforward as it might seem.
        “The definition of accident could be subjective,” says Jon Voegele, agency vice president of Country Financial.
        Policies tend to have a long list of situations when death or injury won’t be covered. Those exclusions can vary
        from company to company and can depend on the state where the policy is issued.
      </p>
      <h3>Benefits are generally not payable for:</h3>
      <ol>
        <li>Injury that happened prior to coverage</li>
        <li>Death caused by illnesses, including mental illness</li>
        <li>Suicide or self-inflicted injuries</li>
        <li>Drug overdose</li>
        <li>Death or injury while driving under the influence of alcohol</li>
        <li>Death or injury from voluntarily inhaling gas or taking poison</li>
        <li>Death or injury while committing a crime</li>
        <li>Death or injury while participating in a riot or suffered during a war</li>
        <li>Injuries while serving in the armed services</li>
        <li>Death or injury from car racing, flying a plane, playing professional sports or participating in extreme
          sports such as sky diving and scuba diving</li>
      </ol>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Free with Purchase
    </button>
  </div>
</section>
<section class="nsurehub-section" id="theft-footer">
  <div class="theft-footerContent">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>

<app-footer></app-footer>