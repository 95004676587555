import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-promotion-tc',
  templateUrl: './promotion-tc.component.html',
  styleUrls: ['./promotion-tc.component.scss']
})
export class PromotionTcComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<PromotionTcComponent>) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close(); 
  }
}
