<app-header></app-header>
<section class="nsurehub-section" id="home-appliance-main">
  <div class="home-appliance-main_content">
    <h1>NsureHub Home Systems Protection</h1>
    <p>A kitchen range enables you to cook the perfect meal. The HVAC system keeps your family cozy on a cold winter
      night. Your electrical system powers the lights, coffee maker, and Wi-Fi, and so much more. It's the appliances
      and systems in your home that help keep your life running smoothly. You probably don't notice your home equipment
      until it stops working.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="protect-investment">
  <h2>Protect Your Most Important Investment</h2>
  <p>As today’s homes rapidly transform into smart homes stocked with high-tech equipment and systems, homeowners become
    more vulnerable. That’s why adding our homeowner’s equipment breakdown endorsement to existing homeowner policies is
    a simple, affordable and comprehensive solution to coverage gaps. Typically, homeowner policies exclude most
    electrical, mechanical and pressure system breakdowns. So when a loss occurs, the homeowner is responsible. When
    faced with the repair or replacement of these sophisticated appliances and systems, the costs could be staggering.
  </p>
</section>
<section class="nsurehub-section" id="protect-property">
  <div class="visionBox">
    <h2>Protect Personal Property and Vital Systems</h2>
    <p>Our simple and straightforward coverage endorsement protects:
    <p>
    <ul>
      <li>Appliances</li>
      <li>Home electronics</li>
      <li>Electrical systems</li>
      <li>Garden tractors</li>
      <li>Heating and cooling systems</li>
      <li>Personal computers</li>
      <li>Security systems</li>
      <li>Swimming pool equipment</li>
      <li>Water heaters</li>
      <li>Well pumps and more</li>
    </ul>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock-1">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="peace-mind">
  <div class="peace-mindTypes">
    <div class="addType">
      <h3>Peace of Mind</h3>
      <p>Our homeowners equipment breakdown coverage is unmatched in its coverage scope and flexibility. Our endorsement
        provides coverage for the real and personal property on a home’s premise. We even include off-premises coverage,
        where we’ll pay for the loss of covered property while away from a covered location.
      </p>
    </div>
  </div>
  <div class="peace-mindTypes">
    <div class="addType">
      <h3>Educate</h3>
      <p>We also help our their insureds by providing property loss prevention tips and other valuable information
        regarding equipment breakdown or systems failure.</p>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="appliance-technology">
  <h2>It’s only technology when it works.</h2>
  <p>Modern home equipment and appliances rely on embedded electronic and electrical components, including sensors. This
    increases their susceptibility to electrical loss and the need for specialized, expensive repairs.
  </p>
  <p>An oven with an electronic motherboard is one example. Its sensitive components may break down more frequently, and
    when they do, skilled repairs may cost more. This is where Equipment Breakdown coverage comes in.
  </p>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="comforts-home">
  <div class="visionBox">
    <h3>Restoring the comforts of home.</h3>
    <p>We know that when we respond to loss, we’re really working to make a house feel like home again. So if your
      policyholder’s oven breaks down tonight, or their furnace stops working on the coldest night of the year, or their
      TV goes black before the big game on Sunday, we are here to help.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="warranties-appliance">
  <h2>Warranties and service contracts aren’t enough.</h2>
  <p>Every home depends on sophisticated equipment to keep life running comfortably. Home and extended warranties may
    offer homeowners a sense of security, but the protection they offer can be very limited and expensive. Home and
    extended warranties are intended for everyday maintenance. With NsureHub Insurance’s Home Systems Protection
    coverage, you can rest easy knowing the cost of breakdowns are covered, and life can go on comfortably.
  </p>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="appliance-footer">
  <div class="appliance-footerContent">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>

<app-footer></app-footer>