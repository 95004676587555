import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-benefit-chart',
  templateUrl: './benefit-chart.component.html',
  styleUrls: ['./benefit-chart.component.scss']
})
export class BenefitChartComponent implements OnInit {

  constructor( private dialogRef:MatDialogRef<BenefitChartComponent>,private router:Router) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close(); 
  }
  closePopup1(){
    this.dialogRef.close(true); 
  }
  

}
