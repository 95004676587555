import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FAQComponent implements OnInit {
  activeAnswer: number = 0;
  faqList: any = [
    {
      question: "Are you licensed to sell insurance?",
      answer: "Yes! You can Verify our license in the Department of Financial Services. Look up our FL License #: L117142 and TX License #: 2839877 and LA License #: 1007655"
    },
    {
      question: "What Is your financial rating?",
      answer: "SafePoInt is proud to be rated favorably by leading independent rating organizations. These companies specialize in reviewing, analyzing, and attesting to insurance companies’ financial security and their ability to deliver on promises made to policyholders. Demotech has assigned the rating of A Exceptional to SafePoint This financial stability rating is an indication of an insurance company’s financial strength. According to Demotech, an A rating means Safepoint has an Exceptional ability to maintain liquidity of invested assets, quality reinsurance, acceptable financial leverage and realistic pricing while simultaneously establishing loss and loss adjustment expense reserves at reasonable levels."
    },
    // {
    //   question: "Where are you located ( Besides online)?",
    //   answer: "You may buy your policy entirely online, by chat, or call us at 1-888-678-7266"
    // },
    {
      question: "Is NsureHub Home insurance Available in my area?",
      answer: "We offer all our coverage and policies in Florida, Texas, and Louisiana."
    },
    {
      question: "How NsureHub Home insurance different from traditional insurance?",
      answer: "We’ve made home Insurance better in a few ways. We let you add coverage the competitors do not have to suit your unique needs, for starters. We’ve also designed the entire NsureHub experience so you can complete your policy online in a matter of just a few clicks with support and chat features. We do all the thinking, so you don’t. We make it simple, easy, and transparent just for you."
    },
    {
      question: "How do I get NsureHub Home insurance?",
      answer: "You can Customize and pay for your home insurance policy right here, online. Just click Start My Quote on the Home page and we will start it for you. It is that easy. We make it simple, easy, and transparent just for you."
    },
    {
      question: "I already have Home Insurance. How do I switch to NsureHub?",
      answer: "Once you input everything about your new policy information in “Start My Quote” on the Home page. Please call us at 1-888-678-7266, so we can handle the cancellation."
    },
    {
      question: "Can I sign up over the phone?",
      answer: "Our Experience is built for you to complete online, but our team of licensed agents can help you get started. If you have any questions, contact us toll-free at 1-888-678-7266 or chat right now."
    },
    {
      question: "Do you offer other types of insurance? Auto?",
      answer: "Yes, just for you. Contact us for an Auto insurance policy today."
    }

  ]
  constructor() { }

  ngOnInit(): void { }

  showAnswer(id: any) {
    this.activeAnswer = id;
  }
}
