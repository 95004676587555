<app-header></app-header>
<section class="nsurehub-section" id="fl-ho-main">
  <div class="fl-ho-main_content">
    <h1>Get a car insurance quote: fast, free and personalized</h1>
    <p>With NsureHub, you can get a car insurance quote in as little as four minutes from a top-rated company. That's
      all it takes to get the customized protection you need at a price that works for you. Get an auto insurance quote
      today and discover how we help you save and give you peace of mind
    </p>
  </div>
</section>

<section class="nsurehub-section" id="fl-ho-additionalCov_1">
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Driver-based auto discounts</h3>
      <p>Snapshots: Safe driver discount.</p>
      <p>Average savings: $146 Read the associated for this Snapshot claim.</p>
      <p>Our Sanpshot programme personalizes your rate based on your actual driving.
        The safer you drive, the more you can save. See more on Snapshot, or sign up for Snapshot Road Test to see what
        you could save without leaving your current insure.
      </p>

      <h3>Teen driver discount</h3>
      <p>Average savings vary Read the associated disclosure for this claim.</p>
      <p>Adding a teen driver to your policy can increase the price - offset the cost a bit with our teen driver
        discount (driver must be 18 years or under). Learn more about car insurance for teens.
      </p>

      <h3>Good students discount</h3>
      <p>Average savings: 10% Read the associated disclosere for this cliam.</p>
      <p>A smart student makes you a smart shopper. Add a full-time student on your policy who maintains a "B" average
        or better, and we'll add a car insurance discount.
      </p>
      <h3>Distant students discount</h3>
      <p>Average savings vary Read the associated disclosure for the claim.</p>
      <p>If you have a full-time students on your policy who is 22 years of age or younger and lives more then 100 miles
        from your home, you'll receive a discount - as long as the students doesn;t have a vehicle while at school.
      </p>
      <h3>Homeowner discount</h3>
      <p>Average savings: nearly 10% Read the associated disclosere for this cliam.</p>
      <p>You'll get another discount simply for being a homeowner, even if your home isn't
        insured through our network of home insurers.
      </p>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" routerLink="/auto-turborater">Get a Quote</button>
  </div>
</section>

<section class="nsurehub-section" id="covered-features">
  <div class="covered-features_content">
    <br>
    <br>
    <h1>Depending on your state, you might be able to add these types of coverage to your policy:</h1>
    <div class="featuresList">
      <div class="feature">
        <h4>Gap insurance</h4>
        <p>Under this coverage, pays up to 25% above the value of tour car if it's totaled. to cover a loan or lease
          balance.You must purchase both comprehensive and collision coverage to buy gap insurance froim Progressive.
        </p>
      </div>
      <div class="feature">
        <h4>Custom parts and equipment value</h4>
        <p>Pays to fix or replace custom accessories you add to your car, up to $5000.
        </p>
      </div>
      <div class="feature">
        <h4>Pet injury insurance </h4>
        <p>Included if you buy collision coverage. It pays veterinarian bills if your pet is injured in a car accident.
        </p>
      </div>
      <div class="feature">
        <h4>Deductible savings bank</h4>
        <p>If you have comprehensive and collision coverage, you can opt in to this program that reduces your
          deductibles
          by $50 every six months you're claim-free, all the way down to $0.
        </p>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
</section>

<section class="nsurehub-section" id="fl-ho-additionalCov">
  <h2>More Ways to save on car insurance</h2>
  <div class="addcoverageTypes">
    <div class="addcoverageType">
      <h3>Avoid tickets and accidents</h3>
      <p>With safe driving = extra saving. If you've had no tickets or accidents in the past three years, your policy
        price could average 31% less then customers who have accidents or violations.
      </p>
    </div>
    <div class="addcoverageType">
      <h3>Small Accident Forgiveness</h3>
      <p>Small Accident Forgivness: Starting with your first policy term, we won't raise your rate on the claim less
        then $500.</p>
    </div>
    <div class="addcoverageType">
      <h3>Large Accident Forgiveness</h3>
      <p>If you've been a customer for at least five years, remained accident and violation-free for up to five years,
        your rate won't increase if you have an accident</p>
    </div>
    <div class="addcoverageType">
      <h3>Accident Forgiveness</h3>
      <p>Read the associated disclosure for this claim. You can purchase additional accident forgivness benefits. When
        you buy Accident Forgivness, you may be eligible to have one accident forgivness per policy period, in addition
        to any accidents that qualify for Samll and Large Accident Forgivness.
      </p>
    </div>
  </div>
</section>

<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" routerLink="/auto-turborater">Get a Quote</button>
  </div>
</section>
<section class="nsurehub-section" id="pet-imagesection_1">
  <div class="pet-imagesectionContent_1">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>

<app-footer></app-footer>