<app-header></app-header>
<section class="nsurehub-section" id="faq-main">
  <h1>Frequently Asked Question's</h1>
  <div class="faqContainer">
    <div class="faqQuestionsBlock">
      <ul>
        <li *ngFor="let faq of faqList;let i=index;" (click)="showAnswer(i)">
          <div [class.active] = "activeAnswer === i"class="roundDot"></div><label>{{faq.question}}</label>
          <mat-icon mat-dialog-close class="close">keyboard_arrow_right</mat-icon>
          <div class="faqAnswersBlock" *ngIf="activeAnswer==i">
            <h3>{{faq.question}}</h3>
            <p>{{faq.answer}}</p>
          </div>
        </li>

      </ul>
    </div>


  </div>
</section>
<app-footer></app-footer>