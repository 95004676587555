import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { UnhandledExceptionsHandler } from './interceptor/error.handler';
import { HttpErrorInterceptor } from './interceptor/error.interceptor';
import { HomeComponent } from './home/home.component';

import { PetInsuranceComponent } from './pet-insurance/pet-insurance.component';
import { FloodInsuranceComponent } from './flood-insurance/flood-insurance.component';
import { AutoInsuranceComponent } from './auto-insurance/auto-insurance.component';
import { BlogInsuranceComponent } from './blog-insurance/blog-insurance.component';
import { LifeInsuranceComponent } from './life-insurance/life-insurance.component';
import { CreditRepairComponent } from './credit-repair/credit-repair.component';
import { SickpayPlusComponent } from './sickpay-plus/sickpay-plus.component';
import { HighvalueHomesComponent } from './highvalue-homes/highvalue-homes.component';
import { TheftIdComponent } from './theft-id/theft-id.component';
import { HomeApplianceComponent } from './home-appliance/home-appliance.component';
import { AutoTurboraterComponent } from './auto-turborater/auto-turborater.component';
import { AccidentDeathComponent } from './accident-death/accident-death.component';
import { SelfInspectionComponent } from './self-inspection/self-inspection.component';
import { LossPreventionComponent } from './loss-prevention/loss-prevention.component';
import { PolicyChangeComponent } from './policy-change/policy-change.component';
import { GetQuoteComponent } from './get-quote/get-quote.component';

import { AppService } from './services/app.service';
import { SharedModule } from './shared/shared.module';
import { ContactsComponent } from './contacts/contacts.component';
import { FAQComponent } from './faq/faq.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { MaterialModule } from './material.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermComponent } from './term/term.component';
import { FloridaHomeownersComponent } from './florida-homeowners/florida-homeowners.component';
import { TexasHomeownersComponent } from './texas-homeowners/texas-homeowners.component';
import { BenefitChartComponent } from './benefit-chart/benefit-chart.component';
import { KickOutScreenComponent } from './kick-out-screen/kick-out-screen.component';
import { NewsAndPartnersComponent } from './news-and-partners/news-and-partners.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PromotionTcComponent } from './promotion-tc/promotion-tc.component';
import { NsureInterceptor } from './interceptor/http.interceptor';
import { HowtoVideoComponent } from './howto-video/howto-video.component';
import { HomeDiscountsComponent } from './home-discounts/home-discounts.component';
import { KickOutScreenStormComponent } from './kick-out-screen-storm/kick-out-screen-storm.component';
import { CurrencyPipe } from '@angular/common';
import { InstantHomePolicyComponent } from './instant-home-policy/instant-home-policy.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentProcessAEGComponent } from './payment-process-aeg/payment-process.component';
import { IframeKickoutComponent } from './iframe-kickout/iframe-kickout.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
  "bgsColor": "#2945ff",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "rectangle-bounce-pulse-out-rapid",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#2945ff",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "rectangle-bounce-pulse-out",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 300,
  "logoUrl": "assets/images/transparent-8 2.png",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "#2945ff",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "Please be patient while we verify your data.",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactsComponent,
    FAQComponent,
    AboutusComponent,
    PrivacyPolicyComponent,
    TermComponent,
    FloridaHomeownersComponent,
    TexasHomeownersComponent,
    BenefitChartComponent,
    KickOutScreenComponent,
    NewsAndPartnersComponent,
    TestimonialsComponent,
    PromotionTcComponent,
    HowtoVideoComponent,
    PetInsuranceComponent,
    FloodInsuranceComponent,
    HomeDiscountsComponent,
    AutoInsuranceComponent,
    BlogInsuranceComponent,
    KickOutScreenStormComponent,
    LifeInsuranceComponent,
    CreditRepairComponent,
    SickpayPlusComponent,
    HighvalueHomesComponent,
    TheftIdComponent,
    HomeApplianceComponent,
    AutoTurboraterComponent,
    AccidentDeathComponent,
    SelfInspectionComponent,
    LossPreventionComponent,
    PolicyChangeComponent,
    GetQuoteComponent,
    InstantHomePolicyComponent,
    PaymentSuccessComponent,
    PaymentProcessAEGComponent,
    IframeKickoutComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: ErrorHandler,
      useClass: UnhandledExceptionsHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NsureInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }, AppService, CurrencyPipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
