<app-header></app-header>
<section class="nsurehub-section" id="fl-ho-main">
  <div class="fl-ho-main_content">
    <h1>Sick Pay Plus Coverage</h1>
    <p>We deliver innovative accident and health insurance programs to meet the unique needs of today’s market. Keep
      your finances healthy when you’re too ill or injured to work. Get monthly benefits paid straight to you. Accident
      and Sickness Protection Pays you cash if you can’t work because of illness and/or injury.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="sickpay-incom-corevalues">
  <h2>How Sick Pay Plus Protects Your Income</h2>
  <div class="coreValuesList">
    <div class="coreValue">
      <img src="../../assets/images/sick-pay/month.png" alt="NsureHub" />
      <h1>Get Up to $1,500 a Month</h1>
      <h6>We’ll send cash benefits without delay, direct to your account.</h6>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/sick-pay/incom.png" alt="NsureHub" />
      <h1>Income From Day One</h1>
      <h6>Payments start the moment you’re too ill* or injured to work and under a doctor’s care.</h6>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/sick-pay/safety.png" alt="NsureHub" />
      <h1>A Six-Month Safety Net</h1>
      <h6>Depending on the type of illness, you could get up to half a year’s worth of pay</h6>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/sick-pay/clock.png" alt="NsureHub" />
      <h1>Round-The-Clock Coverage</h1>
      <h6>Our protection works 365 days a year, 24 hours a day</h6>
    </div>
    <div class="coreValue" style="margin-left: 30%;">
      <img src="../../assets/images/sick-pay/cover.png" alt="NsureHub" />
      <h1>We Cover Most Jobs – Including New Ones</h1>
      <h6>Sick Pay Plus is suited to most professions. And, we’ll still cover you if you change jobs.</h6>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="fl-ho-cta2">
  <div class="benefitChartCTABlock_1">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Click to
      Purchase Sick Pay Plus
    </button>
  </div>
</section>
<section class="nsurehub-section" id="fl-ho-additionalCov_1">
  <h2>Spend the Payments However You Like – Including:</h2>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Deductibles on your insurance</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Out-of pocket medical costs</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Monthly payments, like rent, bills and childcare</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Everyday expenses, like childcare, groceries and transport</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>And of course, any last-minute home remedies – like cough syrup, soup or herbal teas</h3>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
    onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Click to
      Purchase Sick Pay Plus
    </button>
  </div>
</section>
<section class="nsurehub-section" id="sickpay-corevalues">
  <h2>Sick pay plus 100%</h2>
  <p>We’ll walk you through our different plans and benefit amounts – plus the conditions we cover.</p>
  <div class="sickValuesList">
    <div class="sickValue">
      <img src="../../assets/images/sick-pay/month.png" alt="NsureHub" />
      <h1>$800</h1>
      <label>per month</label>
    </div>
    <div class="sickValue">
      <img src="../../assets/images/sick-pay/month.png" alt="NsureHub" />
      <h1>$1000</h1>
      <label>per month</label>
    </div>
    <div class="sickValue">
      <img src="../../assets/images/sick-pay/month.png" alt="NsureHub" />
      <h1>$1500</h1>
      <label>per month</label>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Click to Purchase Sick Pay Plus
    </button>
  </div>
</section>
<section class="nsurehub-section" id="pet-imagesection_1">
  <div class="pet-imagesectionContent_1">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>

<app-footer></app-footer>