<app-header></app-header>
<section class="nsurehub-section" id="about-main">
  <div class="about-main_content">
    <h1>NsureHub Insurance Blog</h1>
    <p>Learn tips, tricks and get independent agent advice for at home, on the road and everything in between.</p>
  </div>
</section>

<section class="nsurehub-section" id="home-discount-corevalues">
  <h2>Understand Your Insurance With the NsureHub Blog.</h2>
  <p>This blog contains information on topics related to personal insurance to help you understand your insurance needs.
  </p>
  <hr width="100%" color="blue" />
  <div class="coreValuesList">
    <div class="coreValue">
      <section class="nsurehub-section" id="about-video">
        <video controls poster="">
          <source src="../../assets/videos/1-Backdated.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </section>
      <h1>Can Homeowners Insurance Be Backdated – Florida</h1>
    </div>
    <div class="coreValue">
      <section class="nsurehub-section" id="about-video">
        <video controls poster="">
          <source src="../../assets/videos/2-Lawsuit.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </section>
      <h1>Will Homeowners Insurance Cover a Civil Lawsuit – Florida</h1>
    </div>
    <div class="coreValue">
      <section class="nsurehub-section" id="about-video">
        <video controls poster="">
          <source src="../../assets/videos/3-Reinstated.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </section>
      <h1>Can Homeowners Insurance Be Reinstated – Florida</h1>
    </div>
    <div class="coreValue">
      <section class="nsurehub-section" id="about-video">
        <video controls poster="">
          <source src="../../assets/videos/4-Transferred.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </section>
      <h1>Can Homeowners Insurance Be Transferred – Florida</h1>
    </div>
    <div class="coreValue">
      <section class="nsurehub-section" id="about-video">
        <video controls poster="">
          <source src="../../assets/videos/roof-coverage.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </section>
      <h1>Is There Homeowners Insurance Without Roof Coverage? (Florida)</h1>
    </div>
    <div class="coreValue">
      <section class="nsurehub-section" id="about-video">
        <video controls poster="">
          <source src="../../assets/videos/paid-monthly.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </section>
      <h1>Can My Homeowners Insurance Be Paid Monthly? (Florida)</h1>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" *ngIf="false"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="pet-imagesection_1">
  <div class="pet-imagesectionContent_1">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>