import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AppService } from '../services/app.service';

@Injectable({
  providedIn: 'root'
})

export class HttpErrorInterceptor implements HttpInterceptor{

  constructor(private toastrService: ToastrService,private appService:AppService){}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if(!request.url.includes('SetReport')){
            this.appService.setReport(error.error.message).subscribe((res:any)=>{});
          };
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
            this.toastrService.error(errorMsg, 'Client Error');
          }
          else {
            if(error.status == 401) {
             // window.location.pathname = '/login';
            } 
            else if(error.status == 200) {
              //return true;
            }
            else if(error.status == 400){ //Bad Request
              let message = error.error?.message
              this.toastrService.error(message?message:'Something Went Wrong!!', 'Error');
            } 
            else {
              if(error.status != 412) {
                errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                this.toastrService.error(errorMsg, 'Error');
              }
             
            }
          }
          return throwError(errorMsg);
        })
      )
  }
}