import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { AppService } from '../services/app.service';
import * as converter from 'xml-js';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';

import * as _moment from 'moment';
import * as moment from 'moment';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermComponent } from '../term/term.component';
import { BenefitChartComponent } from '../benefit-chart/benefit-chart.component';
import { KickOutScreenComponent } from '../kick-out-screen/kick-out-screen.component';
import { PromotionTcComponent } from '../promotion-tc/promotion-tc.component';
import { HowtoVideoComponent } from '../howto-video/howto-video.component';
import { KickOutScreenStormComponent } from '../kick-out-screen-storm/kick-out-screen-storm.component';


declare var google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dataFinderResults: any;
  clientAddress: any;
  showLoading: boolean = false;
  showHomepageScreen: boolean = true;
  showPrivacyScreen: boolean = false;
  showTermsScreen: boolean = false;
  termsChecked: boolean = false;
  autocompleteaddress: boolean = false;
  isAddressFilled: boolean = false;
  userAddressLat: any;
  userAddressLong: any;
  showLoading1: boolean = false;
  showRemainingTC: boolean = false;
  errorObject: any = {
    source: "",
    details: ""
  };
  isDebugEnabled: boolean = false;
  writingCompany: string = "Carrier";

  constructor(private meta: Meta, private appService: AppService, private router: Router, private dialog: MatDialog) {
    this.isDebugEnabled = this.appService.getEnvironment().debugEnabled;
    this.appService.currentStep = 0;
    this.meta.updateTag({
      content: `Protect all you love and care about, including your home, car, motorcycle, boat, pets
    and business. Talk to our agent and Get your home insurance quote now!`}, 'name="description"');
    this.meta.updateTag({ content: `NsureHub | Affordable &amp; Easy Insurance for Your Everyday Needs` }, 'name="title"')
    this.scrollTop();
  }

  ngOnInit(): void {

  }
  autocompleteAddress() {
    var that = this;
    const input = document.getElementById("pac-input") as HTMLInputElement;
    const options = {
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      types: ["cities"],
    };
    this.clientAddress = {};
    this.isAddressFilled = false;
    const autocomplete = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, 'place_changed', async function () {
      var place = autocomplete.getPlace();
      that.autocompleteaddress = true;

      let latlong: any = await that.getLatLang();
      that.userAddressLat = latlong.lat;
      that.userAddressLong = latlong.lng;
      that.clientAddress = {
        formatted_address: place.formatted_address,
        name: place.name,
        city: place.vicinity,
        state: place.formatted_address.split(",")[2].split(" ")[1]
      }
      that.isAddressFilled = true;

    })


  }
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });
  }

  getl() {
    this.getPosition().then(pos => {
      this.appService.getAddressByLatLang(pos.lat, pos.lng).subscribe((res: any) => {
        if (res && res.results) {
          this.userAddressLat = pos.lat;
          this.userAddressLong = pos.lng;
          let input = document.getElementById("pac-input") as HTMLInputElement;
          this.autocompleteaddress = false;

          let addressCity = res.results[0].address_components.find((x: any) => x.types.includes("locality")).short_name;
          let addressZip = res.results[0].address_components.find((x: any) => x.types.includes("postal_code")).short_name;
          let addressState = res.results[0].address_components.find((x: any) => x.types.includes("administrative_area_level_1")).short_name;

          input.value = res.results[0].formatted_address;
          this.clientAddress = {
            formatted_address: input.value,
            name: input.value.split(",")[0] + "," + input.value.split(",")[1],
            city: addressCity,
            state: addressState,
            zip: addressZip
          }


          this.isAddressFilled = true;
        }
      });
    });
  }

  //data = [GIC, Infutor, Data Finder, Data Finder Contact]
  dataMapping(data: any): boolean {
    let response: any = {
      locationNotFoundError: false,
      isInfutorError: false,
      isGicError: false,
      noError: false
    };
    //We get this error if the loaction falls in the middle of the street outside the adjacent parcel boundaries. 
    //So, using a search_radius of 10 m to get the closest parcel. If still unfound, the location could be uncached or historic data.
    if (data[0] && (data[0]?.message == "The job was launched. Please, wait for the asynchronous response." ||
      !data[0]?.property_features || data[0]?.structures?.length == 0)) {
      response.locationNotFoundError = true;
      this.errorObject.source = "GIC";
      this.errorObject.details = {
        info: "Location not found",
        message: data[0].message
      };
      this.showLoading = true;
      this.showKickOut();
      return response;
    }
    if (data[0] && data[0].property_features && data[0].structures.length > 0) {
      if (data[0].structures[0].roof_condition.value < 3 ||
        (data[0].property_features.diving_board.detected) || (data[0].property_features.playground.detected) ||
        (data[0].property_features.trampoline.detected) || (data[0].property_features.water_slide.detected)
      ) {
        response.isGicError = true;
        this.errorObject.source = "GIC";
        this.errorObject.details = {
          roofScore: data[0].structures[0].roof_condition.value,
          features: JSON.stringify(data[0].property_features)
        };
        this.showLoading = true;
        if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "FL")
          this.appService.isBasicAloneDisplayed = true;
        else
          this.showKickOut();
        return response;
      }
    }

    let convertedInfutorResp = converter.xml2json(data[1], { compact: true, spaces: 2 });
    let infutorResponseJSON = JSON.parse(convertedInfutorResp);
    if (!infutorResponseJSON.xml.Response) {
      response.isInfutorError = true;
      this.errorObject.source = "Infutor";
      this.errorObject.details = "No Records Found";
      this.showLoading = true;
      this.showKickOut();
      return response;
    }
    else {
      // if (data[3].versium.results.length > 0) {
      //   if (data[3].versium.results[0].Phone)
      //     this.appService.DCJSON.AccountDetails.Account.PrimaryPhone = data[3].versium.results[0].Phone;
      //   if (data[3].versium.results[0]['First Name'])
      //     this.appService.DCJSON.AccountDetails.Account.FirstName = data[3].versium.results[0]['First Name'];
      //   if (data[3].versium.results[0]["Last Name"])
      //     this.appService.DCJSON.AccountDetails.Account.LastName = data[3].versium.results[0]["Last Name"];
      //   if (data[3].versium.results[0]["Email Address"])
      //     this.appService.DCJSON.AccountDetails.Account.Email = data[3].versium.results[0]["Email Address"];
      // }

      if (data[0].structures[0].roof_shape.value == 'hip') {
        this.appService.DCJSON.RiskDetails.RoofGeometry = "Hip";
      }
      else if (data[0].structures[0].roof_shape.value == 'gable') {
        this.appService.DCJSON.RiskDetails.RoofGeometry = "Gable";
      }
      this.appService.DCJSON.RiskDetails.RoofConditionScore = data[0].structures[0].roof_condition.value.toString();
      let propAttributes = infutorResponseJSON.xml.Response.Detail.IDAttributes.PropertyAttributes;
      if (propAttributes.Attributes.PROP_YRBLD._text)
        this.appService.DCJSON.AccountDetails.PropertyAddress.InfutorYearBuilt = propAttributes.Attributes.PROP_YRBLD._text;
      if (propAttributes.Attributes.PROP_LIVINGSQFT._text)
        this.appService.DCJSON.AccountDetails.PropertyAddress.InfutorSquareFeet = propAttributes.Attributes.PROP_LIVINGSQFT._text;

      let mappingsFields = this.appService.DCMapping;
      if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "TX") {
        mappingsFields = this.appService.DCMappingTX;
      }
      else if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "LA") {
        mappingsFields = this.appService.DCMappingLA;
      }

      for (const [key, value] of Object.entries(mappingsFields)) {
        for (const [key1, value1] of Object.entries(propAttributes)) {
          let ob = value1 as any;
          for (const [finalkey, finalvalue] of Object.entries(ob)) {
            let valueforOb: any = finalvalue;
            if (key == finalkey) {

              for (let i = 0; i < value.length; i++) {
                let foundvalue = "";
                let defaultvalue = "";
                if (value[i].InfutorResponse.includes("undefined")) {
                  defaultvalue = value[i].dcMatch;
                }
                if ((value[i].InfutorResponse.includes(valueforOb._text)) || (valueforOb._text == undefined && value[i].InfutorResponse.includes("undefined"))) {
                  foundvalue = value[i].dcMatch;
                }
                if (foundvalue) {
                  let apifield: string = value[i].dcAPIField;

                  this.appService.DCJSON.RiskDetails[apifield] = foundvalue;
                  break;
                }

                if (i == value.length - 1) {
                  if (!foundvalue) {
                    foundvalue = defaultvalue;

                  }
                  let apifield = value[i].dcAPIField;
                  this.appService.DCJSON.RiskDetails[apifield] = foundvalue;
                }
              }
            }
          }
        }
      }

      let checkIfAOInterestEmpty = this.appService.checkIfAOInterestEmpty();
      let checkIfCoApplicantEmpty = this.appService.checkIfCoApplicantEmpty();
      if (checkIfAOInterestEmpty) {
        delete this.appService.DCJSON.AccountDetails.AOInterest;
      }
      if (checkIfCoApplicantEmpty) {
        delete this.appService.DCJSON.AccountDetails.CoApplicant;
      }
    }

    if (data[2].versium.results.length > 0) {
      //FL
      if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "FL" && this.appService.DCJSON.RiskDetails.NumberofStories) {
        delete this.appService.DCJSON.RiskDetails.NumberofStories;
      }
      //for TX
      else if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "TX") {
        //Update Childrens
        if (data[2].versium.results[0]["Presence of Children"] == "Yes") {
          this.appService.DCJSON.RiskDetails["NumberOfChildren"] = "1";
        }
        else {
          this.appService.DCJSON.RiskDetails["NumberOfChildren"] = "0";
        }
        delete this.appService.DCJSON.RiskDetails.CarportsPoolCagesLimit;
        delete this.appService.DCJSON.AccountDetails.UnderwritingQuestions;
        this.appService.DCJSON.RiskDetails.LOBType = "TXHomeownersAdvantage";
      }
      else if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "LA") {
        this.appService.DCJSON.RiskDetails.LOBType = "LAHomeownersAdvantage";
        this.appService.DCJSON.RiskDetails.ExteriorWallType = "BrickConcreteStoneVeneer";
        this.appService.DCJSON.Flags.PackageType = "Enhanced";
        this.appService.DCJSON.RiskDetails.NumberofStories = "1";
        // if (this.appService.DCJSON.RiskDetails.NumberofStories) {
        //   delete this.appService.DCJSON.RiskDetails.NumberofStories;
        // }
      }
    }
    else {
      if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "FL") {
        this.appService.DCJSON.RiskDetails.LOBType = "HomeownersAdvantage";
        if (this.appService.DCJSON.RiskDetails.NumberofStories) {
          delete this.appService.DCJSON.RiskDetails.NumberofStories;
        }
      }
      else if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "TX") {
        delete this.appService.DCJSON.RiskDetails.CarportsPoolCagesLimit;
        delete this.appService.DCJSON.AccountDetails.UnderwritingQuestions;
        this.appService.DCJSON.RiskDetails.LOBType = "TXHomeownersAdvantage";
        this.appService.DCJSON.RiskDetails["NumberOfChildren"] = "1";
        this.appService.DCJSON.AccountDetails.Account["MaritalStatus"] = "M";
      }
      else if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "LA") {
        this.appService.DCJSON.RiskDetails.LOBType = "LAHomeownersAdvantage";
        this.appService.DCJSON.RiskDetails.ExteriorWallType = "BrickConcreteStoneVeneer";
        this.appService.DCJSON.Flags.PackageType = "Enhanced";
        this.appService.DCJSON.RiskDetails.NumberofStories = "1";
      }
      else if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "AL") {
        this.appService.DCJSON.RiskDetails.LOBType = "ALHomeownersAdvantage";
        this.appService.DCJSON.RiskDetails.ExteriorWallType = "BrickConcreteStoneVeneer";
      }
    }

    //Updates user input address to quote request
    this.appService.DCJSON.AccountDetails.PropertyAddress["OriginalAddress"] = this.clientAddress.formatted_address;

    //Made the default value of Marital Status as Single (S) common to all 3 states as per NHUB-21
    this.appService.DCJSON.AccountDetails.Account["MaritalStatus"] = "S";

    response.noError = true;
    return response;
  }

  async startQuote() {
    (await this.fetchUserData()).subscribe((res: any) => {
      let dataMapResponse: any = this.dataMapping(res);
      if (this.appService.DCJSON.RiskDetails.NumberofStories == "NONE") {
        this.showLoading = true;
        this.showKickOut();
      }
      else {
        if (dataMapResponse && dataMapResponse['noError'] || (this.appService.DCJSON.AccountDetails.PropertyAddress.State == "FL" && dataMapResponse['isGicError'])) {
          this.appService.DCJSON.AccountDetails.Account.EffectiveDate = moment(Date.now()).format('YYYY-MM-DD');
          this.appService.DCJSON.AccountDetails.Account.WritingCompany = this.writingCompany;
          this.appService.createQuote(this.appService.DCJSON)?.subscribe((res: any) => {
            this.appService.currentStep = 2;
            if (res.Error) {
              this.showLoading = true;
              this.showKickOut();
            }
            else if (res.ratingMessages && res.ratingMessages.ineligibleList && res.ratingMessages.ineligibleList.some((message : any) => message.includes("We are not currently accepting new business"))) {
              this.showLoading = true;
              this.showKickOut();
            }
            else {
              let enhanceCoverageDetails = res.coveragesList?.find((x: any) => x.type.toLowerCase() == 'enhanced');
              if (this.appService.DCJSON.AccountDetails.PropertyAddress.State == 'FL') {
                if ((Number(enhanceCoverageDetails?.yourHomeRebuild) < 250000) || (Number(enhanceCoverageDetails?.yourHomeRebuild) > 750000)) {
                  this.errorObject.source = "HOMEREBUILD";
                  this.errorObject.details = {
                    yourHomeRebuild: res.yourHomeRebuild
                  }
                  this.showLoading = true;
                  this.appService.isBasicAloneDisplayed = true;
                }
                localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
                this.router.navigate(['/quote/' + this.appService.DCJSON.AccountDetails.PropertyAddress.State.toLowerCase() + "/premium"]);
              }
              else {
                if ((Number(enhanceCoverageDetails.yourHomeRebuild) < 100000) || (Number(enhanceCoverageDetails.yourHomeRebuild) > 500000)) {
                  this.errorObject.source = "HOMEREBUILD";
                  this.errorObject.details = {
                    yourHomeRebuild: enhanceCoverageDetails.yourHomeRebuild
                  }
                  this.showLoading = true;
                  this.showKickOut();
                }
                else {
                  localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
                  this.router.navigate(['/quote/' + this.appService.DCJSON.AccountDetails.PropertyAddress.State.toLowerCase() + "/premium"]);
                }
              }
            }
          }, () => {
            this.showLoading = true;
            this.showKickOut();
          });
        }
      }
    }, () => {
      this.showLoading = true;
      this.showKickOut();
    });

  }

  openPrivacyPolicy() {
    this.dialog.open(PrivacyPolicyComponent, {
      height: "80vh"
    });
  }
  openTermsPopup() {
    this.dialog.open(TermComponent, {
      height: "80vh"
    });
  }
  openhowtoVideosPopup() {
    this.dialog.open(HowtoVideoComponent, {
      height: "80vh"
    });
  }
  openbenefitChart() {
    let dialogRef = this.dialog.open(BenefitChartComponent, {
      height: "90vh"
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.scrollTop();
      }

    });
  }
  openPromotionPopup() {
    this.dialog.open(PromotionTcComponent, {
      id: "newsPartnerPopup",
      height: "90vh",
      // width:"400px"
    });
  }

  showKickOut() {
    this.dialog.open(KickOutScreenComponent, {
      id: "kickOutPopup",
      height: "40vh",
      data: this.errorObject
    });
  }
  closePopups() {
    this.showHomepageScreen = true;
    this.showPrivacyScreen = false;
    this.showTermsScreen = false;
  }
  checkboxChanges(values: any): void {
    if (values.currentTarget.checked) {
      this.termsChecked = true;
    } else {
      this.termsChecked = false;
    }

  }
  closeKickoutPopup() {
    this.showLoading = false;
  }
  closeKickoutPopup1() {
    this.showLoading1 = false;
  }

  public async fetchUserData(): Promise<any> {
    try {
      let token: any = localStorage.getItem("GIC_TOKEN");
      let expiryDate: any = localStorage.getItem("GIC_TOKEN_EXPIRY");
      let addressDetails = this.generateAddress();
      if ((!token) || Date.parse(expiryDate) < Date.now()) {
        token = await this.appService.getGICToken();
      }
      console.log("finalAddress==>", addressDetails);

      let gicAPI = this.appService.gicAPI(localStorage.getItem("GIC_TOKEN"), this.userAddressLat, this.userAddressLong);
      let infutorAPI = this.appService.infutorAPI(addressDetails);
      let dataFinderAPI = this.appService.dataFinder(addressDetails.address, addressDetails.city, addressDetails.state, addressDetails.zip);
      let dataFinderContactAPI = this.appService.dataFinderContact(addressDetails.address, addressDetails.city, addressDetails.state, addressDetails.zip);
      return forkJoin([gicAPI, infutorAPI, dataFinderAPI, dataFinderContactAPI]);
    }
    catch {
      this.showLoading = true;
      this.showKickOut();
      return null;
    }
  }
  generateAddress() {
    let stateInfo;
    let d_address;
    let d_city;
    let d_state;
    let d_zip;
    d_address = this.clientAddress.name;
    d_city = this.clientAddress.city;
    if (this.autocompleteaddress) {
      stateInfo = this.clientAddress.formatted_address.split(",")[2].split(" ");
      d_state = stateInfo[1];
      d_zip = stateInfo[2];
    } else {
      d_state = this.clientAddress.state;
      d_zip = this.clientAddress.zip;
    }
    localStorage.setItem("DC_USER_STATE", d_state);
    this.appService.DCJSON.AccountDetails.PropertyAddress.Address1 = d_address;
    this.appService.DCJSON.AccountDetails.PropertyAddress.City = d_city;
    this.appService.DCJSON.AccountDetails.PropertyAddress.ZipCode = d_zip;
    this.appService.DCJSON.AccountDetails.PropertyAddress.State = d_state;
    if (d_state == 'LA')
      this.writingCompany = 'Cajun';
    else if (d_state == 'TX')
      this.writingCompany = 'Carrier';
    else if (d_state == 'FL')
      this.writingCompany = 'Manatee';
    return {
      address: d_address,
      city: d_city,
      state: d_state,
      zip: d_zip
    }
  }
  getLatLang() {
    return new Promise(resolve => {
      let address: any = document.getElementById("pac-input");
      address = address.value;
      this.appService.getLatLangByAddress(address).subscribe((res: any) => {
        resolve(res.results[0].geometry.location);
      });
    })
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
