import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auto-turborater',
  templateUrl: './auto-turborater.component.html',
  styleUrls: ['./auto-turborater.component.scss']
})
export class AutoTurboraterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
