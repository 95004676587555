import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-iframe-kickout',
  templateUrl: './iframe-kickout.component.html',
  styleUrls: ['./iframe-kickout.component.scss']
})
export class IframeKickoutComponent  implements OnInit {

  frameUrl = '';

  constructor(public dialogRef:MatDialogRef<IframeKickoutComponent>,@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  private sanitizer: DomSanitizer) {
    this.frameUrl= data.frameUrl;
   }

  ngOnInit(): void {
  }

  generateSafeImageUrl(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.frameUrl);
  }

  onCancel(){
    this.dialogRef.close();
  }

}
