<app-header></app-header>
<section class="nsurehub-section" id="fl-ho-main">
  <div class="fl-ho-main_content">
    <h1>Term life insurance is a great choice for people who want to cover financial obligations that are common when
      raising a family.</h1>
    <p>With term life insurance in place, there's a safety net that can provide funds for paying a mortgage, sending
      kids through college or other important concerns if you were no longer around.
    </p>
  </div>
</section>

<section class="nsurehub-section" id="life-atglance">
  <h2>No Medical Exam Life Insurance</h2>
  <div class="glance-content">
    <div class="glance-tab">
      <h4>$3 Million</h4>
      <p>Maximum no-exam<br>coverage</p>
    </div>
    <div class="glance-tab">
      <h4>20-60</h4>
      <p>Eligible ages</p>
    </div>
    <div class="glance-tab">
      <h4>10,15,20,25 or 30 years</h4>
      <p>Term lengths<br>available</p>
    </div>
  </div>
</section>

<section class="nsurehub-section" id="fl-ho-cta2">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" onclick="window.location.href='https://www.ladderlife.com/nsurehub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="home-discount-corevalues">
  <h2>The key features of term life insurance are:</h2>
  <p>It's generally the cheapest way to buy life insurance.
    Term life insurance policies have a specific length of
    time when your rates are locked in.
    There's no cash value in a term life insurance policy.
  </p>
  <div class="coreValuesList">
    <div class="coreValue">
      <h1>Money back guarantee?</h1>
      <h6>Yep! If, for whatever reason, you're not completely happy with your policy, cancel in the first 30 days and
        get
        a full refund. After that, you can cancel any time without any cancellation fees.
      </h6>
    </div>
    <div class="coreValue">
      <h1>100% digital?</h1>
      <h6>The 100% digital - no doctors, no needles, no paperwork - when you apply for $3 million in coverage or
        less.
        Just answer a few questions about your health in the application. For coverage over $3 million, you may be asked
        to complete a quick, free at - home health check.
      </h6>
    </div>
    <div class="coreValue">
      <h1>Why term life insurance vs whole life insurance?</h1>
      <h6>Term insurance protects your family for the 10-30 years they depend on you most. When your term ends, your
        monthly payments end too. Term coverage is usually more affordable than whole coverage, which can last as long
        as you pay for it.
      </h6>
    </div>
    <div class="coreValue">
      <h1>How much life insurance do i need?</h1>
      <h6>10x your salary is the quick math. It may sound like a lot, but think of everything it could be used to cover:
        a mortgage, debt, higher education, an emergency fund and more.
      </h6>
    </div>
    <div class="coreValue" style="margin-left: 30%;">
      <h1>No medical exams</h1>
      <h6>The 100% online process means all you have to do is answer a few health questions instead of setting up
        medical
        exams and blood tests. What traditionally took weeks can now be done in minutes, giving you more time to focus
        on what matters - the reason you bought life insurance in the first place.
      </h6>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="fl-ho-cta2">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" onclick="window.location.href='https://www.ladderlife.com/nsurehub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="life-imagesection">
  <div class="life-imagesectionContent">
    <h3>Why buy life insurance?
      <p>Life insurance is an affordable way to protect your family financially in the event <br>you're not there to
        take
        care
        of them. Life insurance can help with things like paying<br>the mortgage, college tution, final expenses and
        more.
      </p>
      <p>No-exam term life insurance for coverage up to $3M<br>
        Customize your coverage to save money on premiums<br>
        No policy fees and you can cancel anytime</p>
    </h3>
  </div>
</section>
<section class="nsurehub-section" id="fl-ho-additionalCov_1">
  <h2>Reasons to purchase through NsureHub</h2>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>You can purchase your policy in 3 easy steps</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>You're in control of your coverage</h3>
    </div>
  </div>
  <!-- <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Good for the planet, too</h3>
    </div>
  </div> -->
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Our digital application uses zero paper and a tree is planted for every policy.
        Here's to a greener tomorrow.</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>Stability and resilience for your family</h3>
    </div>
  </div>
  <div class="addcoverageTypes_1">
    <div class="addcoverageType_1">
      <h3>$49 + billion coverage provided</h3>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" onclick="window.location.href='https://www.ladderlife.com/nsurehub';">&nbsp; Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="home-discount-corevalues">
  <h2>We've teamed up with Ladder to help you get the term life insurance policy you deserve.</h2>
  <p>Ladder Insurance Services, LLC (CA license # OK22568; AR license # 3000140372) distributes term life insurance
    products issued by multiple insurers – for further details see ladderlife.com. All insurance products are governed
    by the terms set forth in the applicable insurance policy. Each insurer has financial responsibility for its own
    products.
    221201-2618077
  </p>
</section>
<section class="nsurehub-section" id="pet-imagesection_1">
  <div class="pet-imagesectionContent_1">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>

<app-footer></app-footer>