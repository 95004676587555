import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { async } from 'rxjs';
import { AddSmiamLiabilityComponent } from 'src/app/modules/steps/components/step1/add-smiam-liability/add-smiam-liability.component';
import { AnimalLiabilityExclusionComponent } from 'src/app/modules/steps/components/step2/animal-liability-exclusion/animal-liability-exclusion.component';
import { SinkholeCoverageExclusionComponent } from 'src/app/modules/steps/components/step2/sinkhole-coverage-exclusion/sinkhole-coverage-exclusion.component';
import { DialogBox4Component } from 'src/app/modules/steps/components/step4/dialog-box4/dialog-box4.component';
import { AppService } from 'src/app/services/app.service';
import { CurrencyPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { KickOutScreenComponent } from 'src/app/kick-out-screen/kick-out-screen.component';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit {
  @Input() quoteType: any;
  listOfPriceSelector: any = [5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000];
  color: string = '#3A3A3A';
  checked = false;
  disabled = false;
  checkboxLibalityFor: boolean = true;
  checkboxScreenEnclosure: boolean = false;
  panelOpenState = false;
  showPriceSelector: boolean = false;
  showMorePolicyContent: boolean = false;

  totalPremium: any = 3200;

  screenClosurePrice: any = 0;
  animalLiabilityPrice: any = "";
  identityTheftPrice: any = "";
  equipmentBreakdownPrice: any = "";
  screenEnclosureChecked = false;
  animalLiabilityChecked = false;
  identityTheftChecked = false;
  equipmentBreakdownChecked = false;
  dcResponse: any = [];
  localDCResponse: any = [];
  animalLiabilitydisabled: boolean = false;
  state: any;
  waterbackup: any;
  coverageDetails: any;
  @Input('isSelectionEnabled') isSelectionEnabled: boolean = false;
  @Input('type') type: any;
  @Output() premiumUpdateEvent = new EventEmitter<[]>();

  constructor(public dialog: MatDialog, public appService: AppService, private router: Router, private pipe: CurrencyPipe, private toastrService: ToastrService) {
    this.state = localStorage.getItem("DC_USER_STATE");
    this.dcResponse = localStorage.getItem("DC_RESPONSE");
    this.localDCResponse = localStorage.getItem("DCJSONLOCAL");

    this.dcResponse = JSON.parse(this.dcResponse);

    this.localDCResponse = JSON.parse(this.localDCResponse);
    this.screenClosurePrice = this.localDCResponse.RiskDetails.CarportsPoolCagesLimit;
    this.appService.DCJSON.AccountDetails.PropertyAddress = this.localDCResponse.AccountDetails.PropertyAddress;
    if (this.screenClosurePrice != '0') {
      this.screenEnclosureChecked = true;
      this.appService.screenEnclosurePrice = this.screenClosurePrice;
    }
    this.totalPremium = this.dcResponse.premiums.annual.substring(1);
    this.waterbackup = this.dcResponse.waterBackup?.replace(/,/g, '');
    this.appService.DCJSON.Flags.QuoteNumber = this.dcResponse.quoteNumber;
    this.appService.DCJSON.RiskDetails.QuoteNumber = this.dcResponse.quoteNumber;
    if (this.appService.DCJSON.RiskDetails.CarportsPoolCagesLimit != "0") {
      this.screenEnclosureChecked = true;
    }
    if (this.localDCResponse.RiskDetails.EquipmentBreakdown != '0') {
      this.equipmentBreakdownChecked = true;
    }
    if (this.localDCResponse.RiskDetails.IdentityFraudExpenseCoverage != '0') {
      this.identityTheftChecked = true;
    }
    if (this.localDCResponse.RiskDetails.CoverageDogLiability != "0" && this.appService.dogLiablityAnswer) {
      this.animalLiabilitydisabled = true;
      this.animalLiabilityChecked = true;
    }
    if (this.localDCResponse.RiskDetails.CoverageDogLiability != "0" && !this.appService.dogLiablityAnswer) {
      this.animalLiabilityChecked = true;
      if (this.state == 'FL' || this.state == 'LA' || this.state == 'AL') {
        this.animalLiabilityPrice = "$50,000";
      } else if (this.state == 'TX') {
        this.animalLiabilityPrice = "$25,000";
      }
    }
    if (this.localDCResponse.RiskDetails.IdentityFraudExpenseCoverage != "0" && this.identityTheftChecked) {
      if (this.state == 'LA')
        this.identityTheftPrice = "$25,000";
    }
    if (this.localDCResponse.RiskDetails.EquipmentBreakdown != "0" && this.equipmentBreakdownChecked) {
      if (this.state == 'LA')
        this.equipmentBreakdownPrice = "$100,000";
    }
  }
  ngOnInit(): void {
    this.isSelectionEnabled = (this.quoteType != 'final' && this.isSelectionEnabled);
    this.coverageDetails = this.dcResponse.coveragesList.find((x: any) => x.type == this.type);
  }

  ngAfterViewInit() {
    if (!this.isSelectionEnabled && this.quoteType != 'final' && this.type == this.appService.selectedPackage && this.type == "Basic") {
      this.appService.updateQuote(this.appService.DCJSON)?.subscribe((res: any) => {
        if (res.Error){
          this.showKickOut(res.Error);
        }else{
          this.dcResponse.premiums = res.premiums;
          localStorage.setItem("DC_RESPONSE", JSON.stringify(this.dcResponse));
          this.totalPremium = this.dcResponse.premiums.annual.substring(1);
        }
      });
    }
  }

  openDialog() {
    this.appService.currentStep = 3;
    this.router.navigate(['/quote/' + this.appService.DCJSON.AccountDetails.PropertyAddress.State.toLowerCase() + "/account"]);
  }

  openSinkholeDialog() {
    const dialogRef = this.dialog.open(SinkholeCoverageExclusionComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  openAnimalLibalityExclusionDialog() {
    const dialogRef = this.dialog.open(AnimalLiabilityExclusionComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  changeEnclousrePrice() {
    this.showPriceSelector = false;

  }
  applyScreenEnclosure(type: any, event: any) {
    event.stopPropagation();
    if (this.appService.selectedPackage != this.type) {
      event.target.checked = false;
      this.toastrService.info("Please select the Enhanced package to apply the Screen Enclosure/Carport", 'Selection Info');
      this.screenEnclosureChecked = !this.screenEnclosureChecked;
      return;
    }
    let apicall = true;
    if (this.screenEnclosureChecked && type == "toggle" && this.screenClosurePrice == 0) {
      this.showPriceSelector = true;
      apicall = false;
      //  return false;
    }
    if (!this.screenEnclosureChecked && type == "toggle" && this.screenClosurePrice == 0) {
      this.showPriceSelector = false;
      apicall = false;
    } else {

      if ((this.localDCResponse.RiskDetails.CarportsPoolCagesLimit == this.screenClosurePrice && type == "selector")) {
        let price = 0;
        this.localDCResponse.RiskDetails.CarportsPoolCagesLimit = price;
        this.appService.screenEnclosurePrice = price;
        this.appService.DCJSON.RiskDetails.CarportsPoolCagesLimit = price.toString();
        this.screenClosurePrice = 0;
        this.screenEnclosureChecked = false;
      }
      else if (!this.screenEnclosureChecked && type == "toggle") {
        let price = 0;
        this.localDCResponse.RiskDetails.CarportsPoolCagesLimit = price;
        this.appService.screenEnclosurePrice = price;
        this.appService.DCJSON.RiskDetails.CarportsPoolCagesLimit = price.toString();
        this.screenClosurePrice = 0;
        this.screenEnclosureChecked = false;
      }
      else {
        this.localDCResponse.RiskDetails.CarportsPoolCagesLimit = this.screenClosurePrice;
        this.appService.screenEnclosurePrice = this.screenClosurePrice;
        this.appService.DCJSON.RiskDetails.CarportsPoolCagesLimit = this.screenClosurePrice.toString();
        this.screenEnclosureChecked = true;
      }

    }

    if (apicall) {
      this.appService.DCJSON.Flags.Account = "0";
      this.appService.DCJSON.Flags.AOI = "0";
      this.appService.DCJSON.Flags.Coverages = "1";
      this.appService.updateQuote(this.appService.DCJSON)?.subscribe((res: any) => {
        if (res.Error) {
          this.showKickOut(res.Error);
        }else{
          this.premiumUpdateEvent.emit(res.premiums);
          this.dcResponse.premiums = res.premiums;
          localStorage.setItem("DC_RESPONSE", JSON.stringify(this.dcResponse));
          //this.dcResponse = res;
          // this.screenEnclosureChecked = true;
          this.totalPremium = this.dcResponse.premiums.annual.substring(1);
        }
      }, (error: any) => {
        //this.screenEnclosureChecked = false;
      });
    }


  }
  applyAnimalLiability(event: any) {
    event.stopPropagation();
    if (this.appService.selectedPackage != this.type) {
      event.target.checked = false;
      this.toastrService.info("Please select the Enhanced package to apply the Animal Liability", 'Selection Info');
      this.animalLiabilityChecked = !this.animalLiabilityChecked;
      return;
    }
    if (this.animalLiabilityChecked) {
      let dialogRef = this.dialog.open(AddSmiamLiabilityComponent);
      dialogRef.afterClosed().subscribe((result) => {
        this.animalLiabilityChecked = true;
        if (result) {
          this.appService.dogLiablityAnswer = true;
          this.appService.DCJSON.RiskDetails.CoverageDogLiability = "0";
          this.animalLiabilityPrice = "";
          this.animalLiabilitydisabled = true;
        } else {
          this.appService.dogLiablityAnswer = false;
          if (this.state == "TX") {
            this.animalLiabilityPrice = "$25,000";
            this.appService.DCJSON.RiskDetails.CoverageDogLiability = "25000";
          } else if (this.state == "LA" || this.state == "AL") {
            this.animalLiabilityPrice = "$50,000";
            this.appService.DCJSON.RiskDetails.CoverageDogLiability = "50000";
          }
          else if (this.state == "FL") {
            this.animalLiabilityPrice = "$50,000";
            this.appService.DCJSON.RiskDetails.CoverageDogLiability = "1";
          }
          this.animalLiabilitydisabled = false;
        }
        this.appService.DCJSON.Flags.Account = "0";
        this.appService.DCJSON.Flags.AOI = "0";
        this.appService.DCJSON.Flags.Coverages = "1";
        this.appService.updateQuote(this.appService.DCJSON).subscribe((res: any) => {
          if (res.Error) {
            this.showKickOut(res.Error);
          }else{
            this.premiumUpdateEvent.emit(res.premiums);
            // localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
            this.dcResponse = res;
            this.totalPremium = this.dcResponse.premiums.annual.substring(1);
          }
        });
      });

    } else {
      this.appService.DCJSON.RiskDetails.CoverageDogLiability = "0";
      this.appService.DCJSON.Flags.Account = "0";
      this.appService.DCJSON.Flags.AOI = "0";
      this.appService.DCJSON.Flags.Coverages = "1";
      this.appService.updateQuote(this.appService.DCJSON).subscribe((res: any) => {
        if(res.Error) {
          this.showKickOut(res.Error);
        }else{
          this.premiumUpdateEvent.emit(res.premiums);
          // localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
          this.dcResponse = res;
          this.totalPremium = this.dcResponse.premiums.annual.substring(1);
          this.animalLiabilityChecked = false;
          this.animalLiabilityPrice = "";
        }
      }, (error: any) => {
        this.animalLiabilityChecked = false;
      });
    }
  }
  applyIdentityTheft(event: any) {
    event.stopPropagation();
    if (this.appService.selectedPackage != this.type) {
      event.target.checked = false;
      this.toastrService.info("Please select the Enhanced package to apply the Animal Liability", 'Selection Info');
      this.identityTheftChecked = !this.identityTheftChecked;
      return;
    }
    if (this.identityTheftChecked) {
      this.appService.DCJSON.RiskDetails.IdentityFraudExpenseCoverage = "1";
      this.appService.DCJSON.Flags.Account = "0";
      this.appService.DCJSON.Flags.AOI = "0";
      this.appService.DCJSON.Flags.Coverages = "1";
      this.appService.updateQuote(this.appService.DCJSON).subscribe((res: any) => {
        if (res.Error) {
          this.showKickOut(res.Error);
        }else{
          this.premiumUpdateEvent.emit(res.premiums);
          // localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
          this.dcResponse = res;
          this.totalPremium = this.dcResponse.premiums.annual.substring(1);
          if (this.state == 'LA')
            this.identityTheftPrice = "$25,000";
          this.identityTheftChecked = true;
        }
      }, (error: any) => {
        this.identityTheftChecked = false;
      });
    } else {
      this.appService.DCJSON.RiskDetails.IdentityFraudExpenseCoverage = "0";
      this.appService.DCJSON.Flags.Account = "0";
      this.appService.DCJSON.Flags.AOI = "0";
      this.appService.DCJSON.Flags.Coverages = "1";
      this.appService.updateQuote(this.appService.DCJSON).subscribe((res: any) => {
        if (res.Error) {
          this.showKickOut(res.Error);
        }else{
          this.premiumUpdateEvent.emit(res.premiums);
          // localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
          this.dcResponse = res;
          this.totalPremium = this.dcResponse.premiums.annual.substring(1);
          if (this.state == 'LA')
            this.identityTheftPrice = "";
          this.identityTheftChecked = false;
        }
      }, (error: any) => {
        this.identityTheftChecked = false;
      });
    }
  }
  applyEquipmentBreakdown(event: any) {
    event.stopPropagation();
    if (this.appService.selectedPackage != this.type) {
      event.target.checked = false;
      this.toastrService.info("Please select the Enhanced package to apply the Animal Liability", 'Selection Info');
      this.equipmentBreakdownChecked = !this.equipmentBreakdownChecked;
      return;
    }
    if (this.equipmentBreakdownChecked) {
      this.appService.DCJSON.RiskDetails.EquipmentBreakdown = "1";
      this.appService.DCJSON.Flags.Account = "0";
      this.appService.DCJSON.Flags.AOI = "0";
      this.appService.DCJSON.Flags.Coverages = "1";
      this.appService.updateQuote(this.appService.DCJSON).subscribe((res: any) => {
        if (res.Error) {
          this.showKickOut(res.Error);
        }else{
          this.premiumUpdateEvent.emit(res.premiums);
          // localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
          this.dcResponse = res;
          this.totalPremium = this.dcResponse.premiums.annual.substring(1);
          if (this.state == 'LA')
            this.equipmentBreakdownPrice = "$100,000";
          this.equipmentBreakdownChecked = true;
        }
      }, (error: any) => {
        this.equipmentBreakdownChecked = false;
      });
    } else {
      this.appService.DCJSON.RiskDetails.EquipmentBreakdown = "0";
      this.appService.DCJSON.Flags.Account = "0";
      this.appService.DCJSON.Flags.AOI = "0";
      this.appService.DCJSON.Flags.Coverages = "1";
      this.appService.updateQuote(this.appService.DCJSON).subscribe((res: any) => {
        if (res.Error) {
          this.showKickOut(res.Error);
        }else{
          this.premiumUpdateEvent.emit(res.premiums);
          // localStorage.setItem("DC_RESPONSE", JSON.stringify(res));
          this.dcResponse = res;
          this.totalPremium = this.dcResponse.premiums.annual.substring(1);
          if (this.state == 'LA')
            this.equipmentBreakdownPrice = "";
          this.equipmentBreakdownChecked = false;
        }
      }, (error: any) => {
        this.equipmentBreakdownChecked = false;
      });
    }
  }
  getHurricaneDeductible(value: any) {
    if (value == "Excluded") {
      return "string";
    } else {
      if (value < 1 || value.includes('%')) {
        return "percentage";
      } else {
        return "dollor";
      }
    }

  }
  getMoldFungiPrice(value: any): any {
    let prices = value.split("/");
    if (prices.length > 1) {
      return {
        mold: prices[0],
        fungi: prices[1]
      }
    } else {
      return "single";
    }
  }
  showWinddtromPopup(type: string) {
    let dialogRef = this.dialog.open(DialogBox4Component, {
      width: '800px',
      disableClose: true,
      autoFocus: true,
      panelClass: 'my-full-screen-dialog',
      data: type
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  getRespectiveValue(value: any) {
    let actualValue = value;
    if (value == null)
      return actualValue;
    if (value == '1')
      actualValue = 'Included';
    else if (value == '0')
      actualValue = 'Excluded';
    else if (value?.indexOf('.') != -1 && +value < 1)
      actualValue = `${value * 100}%`;
    else if (value?.indexOf('%') != -1 || value == 'Included' || value == 'Excluded')
      actualValue = value;
    else if (+value > 0)
      actualValue = `${this.pipe.transform(value, 'USD', 'symbol', '3.0')}`;
    return actualValue;
  }

  showKickOut(error:any) {
    this.dialog.open(KickOutScreenComponent, {
      id: "kickOutPopup",
      height: "40vh",
      data: error
    });
  }
}
