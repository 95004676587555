import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss']
})
export class TermComponent implements OnInit {
  
  constructor( private dialogRef:MatDialogRef<TermComponent>) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close();
  }

}
