import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-box4',
  templateUrl: './dialog-box4.component.html',
  styleUrls: ['./dialog-box4.component.scss']
})
export class DialogBox4Component implements OnInit {

  state: any = '';

  constructor(private dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.state = localStorage.getItem("DC_USER_STATE");
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  exit() {
    this.dialog.closeAll();
  }

}
