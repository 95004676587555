<div class="center_img">
    <img (click)="closePopup()" src="../../../assets/images/Vector.png" alt="NsureHub" />
  </div>
<div class="newsPartnerContainer">
<h2>NsureHub News and Partners</h2>
<p>Infutor’s integration with the AWS Data Exchange enabled NsureHub to benefit from simple, secure, and privacy-compliant access to consumer data
</p>
<div class="newsPartnercontent">


    <a href="https://www.digitaljournal.com/pr/nsurehub-offers-innovative-transparent-and-hassle-free-way-for-customers-to-buy-home-insurance-in-florida-and-texas-markets">https://www.digitaljournal.com/pr/nsurehub-offers-innovative-transparent-and-hassle-free-way-for-customers-to-buy-home-insurance-in-florida-and-texas-markets</a>

    <a href="https://infutor.com/resource/cs-nsurehub/">https://infutor.com/resource/cs-nsurehub/</a>
    
    
    <a href="https://www.abnewswire.com/pressreleases/nsurehub-innovates-again-and-is-leading-the-charge-in-digital-transformation-in-the-home-insurance-space_613973.html">https://www.abnewswire.com/pressreleases/nsurehub-innovates-again-and-is-leading-the-charge-in-digital-transformation-in-the-home-insurance-space_613973.html</a>
    
    <a href="https://safepointins.com/about-us/financial-strength/">https://safepointins.com/about-us/financial-strength/</a>

    <a href="https://gic.org/">https://gic.org/</a>

    <a href="https://aggne.com/">https://aggne.com/</a>

    <a href="https://www.progressive.com/">https://www.progressive.com/</a>

    <a href="https://figopetinsurance.com/about-us">https://figopetinsurance.com/about-us</a>
</div>
</div>