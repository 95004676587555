<app-header></app-header>
<section class="nsurehub-section" id="flood-main">
  <div class="flood-main_content">
    <h1>Should You Get Flood Insurance? Here's What You Need to Know</h1>
    <p>Depending on where your home is located and what you hope to protect, a flood insurance policy can be a wise
      investment. But you'll want to purchase the policy long before an impending weather event.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="flood-wherewedoBusiness">
  <div class="wherewedoBusiness-right">
    <!-- <h1>Get Afforedable Flood Insurance for Your Home</h1> -->
    <p>As <u>climate change</u> brings about ever more severe weather events, including hurricanes of epic proportion
      that
      trigger torrential rainfalls and flash flooding, the question of whether to obtain flood insurance has never been
      more pressing.</p>

    <p>As the Federal Emergency Management Agency (FEMA) <u>website points out</u>, floods can happen anywhere and even
      a mere
      inch of water can cause up to $25,000 in damage.</p>

    <p>If you're unsure whether flood insurance makes sense for your living situation, here are some key questions and
      considerations to help sort out this important decision.
    </p>
    <p>
      Flood insurance will help you to get back on your feet—but only if you have the right coverage to make the
      necessary repairs and replace your belongings. After any disaster, there are some who find you didn’t have enough
      coverage because you purchased only what the lender required, not what you truly needed.
    </p>
  </div>
  <!-- <div class="wherewedoBusiness-left">
    <img src="../../assets/images/flood-head2.png" />
  </div> -->
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="flood-insurance-section">
  <h2>Flood Insurance</h2>
  <p>If you're unsure whether flood insurance makes sense for your living situation, here are some key questions and
    considerations to help sort out this important decision.
  </p>
</section>
<section class="nsurehub-section" id="flood-insurance-section-content">
  <div class="flood-insurance-content-list">
    <h3>How do you know whether you need flood insurance?</h3>
    <p>Your home's location should be a primary factor in determining whether flood insurance is a worthwhile
      investment.
      However, it's important to keep in mind that FEMA's flood maps can be outdated, meaning your home may have more or
      less flood risk than the agency's map indicates
    </p>
    <h3>How much does flood insurance cost?</h3>
    <p>The monthly cost of flood insurance premiums may be another consideration for homeowners considering such a
      policy. The amount you will typically pay for a policy depends on a variety of factors including your home's flood
      risk designation, the year it was built, its elevation, and how it's constructed.
    </p>
    <h3>What's covered by flood insurance?</h3>
    <p>Depending on what you hope to protect, flood insurance may or may not make sense for your situation. Overall,
      flood policies are available to insure the dwelling itself, and also its contents.
      Building coverage covers your home's foundation, including electrical and plumbing systems, while contents
      coverage covers your personal property, like furniture and electronics,
    </p>
    <h3>Climate change considerations</h3>
    <p>As already mentioned, climate change has intensified rainfall and flood conditions across the United States and
      the world, and this reality is increasingly worrying for Americans.
      Climate change is making hurricanes larger and more destructive, so if you live in an Atlantic or Gulf Coast
      state, it's a good idea to consider flood insurance regardless of your location in the state.
    </p>
    <h3>Where to obtain flood insurance?</h3>
    <p>Homeowners in every state have the option to purchase flood insurance through the National Flood Insurance
      Program managed by FEMA. These policies are usually sold by private insurance companies.
      A majority of flood insurance policyholders have an NFIP policy, and most communities participate in this program,
      so you will likely be able to obtain a NFIP policy,
    </p>
  </div>
</section>
<section class="nsurehub-section" id="flood-imagesection">
  <div class="flood-imagesectionContent">
    <h1>How to Get Flood Insurance
      <br>
      <br>
      <p>While you can’t purchase flood insurance directly from the National Flood Insurance Program, you can purchase
        flood insurance directly from NsureHub. Just apply for a flood insurance quote online – we’ll handle the rest.
      </p>
    </h1>
  </div>
</section>
<section class="nsurehub-section" id="flood-insurance-section">
  <h2>NsureHub Flood Insurance</h2>
</section>
<section class="nsurehub-section" id="flood-insurance-section-content">
  <div class="flood-insurance-content-list">
    <h3>Features that might be found in some NsureHub policies could include:</h3>
    <ul>
      <li>Limits of coverage higher than those provided by the NFIP.</li>
      <li>Replacement cost loss settlement on all building losses.</li>
      <li>Replacement cost loss settlement on personal property.</li>
      <li>Enhanced coverage in basements.</li>
      <li>No “two acre” rule.</li>
      <li>Broader other structures coverage.</li>
      <li>Coverage for other structures, besides just a detached garage.</li>
      <li>Additional living expense on a policy covering homes.</li>
      <li>Business income and extra expense on commercial policies.</li>
      <li>No 30-day wait.</li>
      <li>Single adjuster for wind/flood loss.</li>
      <li>The “property not covered” list may be shorter than NFIP.</li>
      <li>Elevation certificate may not be required be required.</li>
      <li>Possibly lower rates.</li>
    </ul>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="flood-imagesection_1">
  <div class="flood-imagesectionContent_1">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>