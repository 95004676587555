<app-header></app-header>
<section class="nsurehub-section" id="credit-repair-main">
  <div class="credit-repair-main_content">
    <form (submit)="submit()">
      <div class="container mt-5 mb-5 d-flex justify-content-center">
        <div class="card px-1 py-4" style="margin-top: 60px">
          <div class="card-body">
            <!-- <p class="text-center">{{message}}</p> -->
            <h2 class="text-center">Request a personalized quote from NsureHub</h2>
            <!-- <h6 class="information mt-4">To Request a Policy Change, Please Fill
              in your Name, Phone, and Policy Number
              with a Discretion and our Service Department will review within 24
              Hrs.</h6> -->
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mt-3">
                  <label>First Name</label>
                  <input class="form-control" type="text" id="fname" name="fname" [(ngModel)]="fname" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mt-3">
                  <label>Last Name</label>
                  <input class="form-control" type="text" id="lname" name="lname" [(ngModel)]="lname" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mt-3">
                  <label>Email</label>
                  <input class="form-control" type="email" id="email" name="email" [(ngModel)]="email" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mt-3">
                  <label>Phone</label>
                  <div class="input-group">
                    <input class="form-control" type="text" id="phone" name="phone" [(ngModel)]="phone" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mt-3">
                  <label>Address</label>
                  <div class="input-group">
                    <input class="form-control" type="text" id="address" name="address" [(ngModel)]="address"
                      required />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mt-3">
                  <label>State</label>
                  <div class="input-group">
                    <input class="form-control" type="text" id="state" name="state" [(ngModel)]="state" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group mt-3">
                  <label>Zip Code</label>
                  <div class="input-group">
                    <input class="form-control" type="text" id="zipcode" name="zipcode" [(ngModel)]="zipcode"
                      required />
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="quoteTermsPolicyBlock">
              <input type="checkbox" (change)="checkboxChanges($event)" aria-label="check" required />
              <div class="termsText">
                <span>I accept NsureHub's terms and conditions.<label *ngIf="!showRemainingTC"
                    (click)="showRemainingTC = !showRemainingTC" class="seemoreLabel">See
                    more...</label><span class="remainingTC" *ngIf="showRemainingTC">* By
                    accepting the terms and conditions, I acknowledge
                    that
                    the information I provide will be used to contact me using Phone,
                    Email,
                    or SMS. I also understand that NsureHub may send me additional
                    communications regarding NsureHub services.</span><label *ngIf="showRemainingTC"
                    (click)="showRemainingTC = !showRemainingTC" class="seemoreLabel">See
                    less...</label>
                </span>
              </div>
            </div>
            <div class="text-center">
              <p>{{message}}</p>
            </div>
            <br>
            <div class="text-center">
              <button class="button-fill" type="submit" name="submit">Request My Quote</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<app-footer></app-footer>