<div class="testiSectionContent">
    <h2>Here's what our customers say about us....</h2>
    <div class="owl-carousel owl-theme mt-5">
        <div class="testimonialItem">
            <div class="testimonialItemHeader">
                <img src="../../assets/images/nsurehub/sam.png" alt="NsureHub" />
                <label class="clientName">Sam</label>
            </div>
            <div class="testimonialItemContent">
                <p>“Would definitely recommend NsureHub. Finally, I can pat myself on the back for the decision I made to get home insurance from them a year ago. While the fire damaged our home, our home insurance ensured we had the financial cushion to renovate.”</p>
            </div>
        </div>
        <div class="testimonialItem">
            <div class="testimonialItemHeader">
                <img src="../../assets/images/nsurehub/tom.png" alt="NsureHub" />
                <label class="clientName">Tom</label>
            </div>
            <div class="testimonialItemContent">
                <p>“NsureHub made the entire process of navigating the insurance world extremely easy for us. From advising on the right options for us to getting all the paperwork done in a seamless manner, they went above and beyond to cater to all our needs.”</p>
            </div>
        </div>
        <div class="testimonialItem">
            <div class="testimonialItemHeader">
                <img src="../../assets/images/nsurehub/alkan.png" alt="NsureHub" />
                <label class="clientName">Alkan kay</label>
            </div>
            <div class="testimonialItemContent">
                <p>“I have gotten multiple insurance policies from NsureHub and am pleased with my experience so far. If you need customized and economical insurance policies to remain protected for whatever challenges life throws at you, look no further.”</p>
            </div>
        </div>
        <div class="testimonialItem">
            <div class="testimonialItemHeader">
                <img src="../../assets/images/nsurehub/chris.png" alt="NsureHub" />
                <label class="clientName">Chris Z</label>
            </div>
            <div class="testimonialItemContent">
                <p>“NsureHub saved me 40% when Kin decided to double my premium (I don’t have any claims). The agent at NsureHub I spoke with even added some coverages I didn’t know where possible.”</p>
            </div>
        </div>
        <div class="testimonialItem">
            <div class="testimonialItemHeader">
                <img src="../../assets/images/nsurehub/fabby.png" alt="NsureHub" />
                <label class="clientName">Fabby A</label>
            </div>
            <div class="testimonialItemContent">
                <p>“NsureHub is easy to use. I saved almost 50%, while lowering my deductible and changing from cash value to replacement value on my roof. Now I’m better covered and paying less.”</p>
            </div>
        </div>
    </div>
</div>
