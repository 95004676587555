import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { NewsAndPartnersComponent } from '../news-and-partners/news-and-partners.component';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  constructor(private meta: Meta, private dialog: MatDialog) { 
    this.meta.updateTag({ content: `NsureHub is a team of insurance and technology geeks that is redefining the
    insurance industry, one policy at a time. Get the insurance you need quickly, easily, and confidently!`} , 'name="description"' );
    this.meta.updateTag({ content: `About Us | NsureHub`} , 'name="title"' )
    
  }

  ngOnInit(): void {
  }
  openNewsPartnerBox() {
    this.dialog.open(NewsAndPartnersComponent, {
      id: "newsPartnerPopup",
      height: "90vh",
      // width:"400px"
    });
  }

}
