<div>
  <p class="exitButton" (click)="exit()">X</p>
  <div *ngIf="(state != 'LA' && data == 'Limited Liability')">
    <p>
      The insurance policy for which I am applying excludes Liability and Medical Payments to Others coverage for losses
      resulting from recreational vehicles I own. This means that the company will not pay for any amounts I may become
      liable for resulting from any recreational vehicles in my possession or owned by me.
    </p>
    <p><b>Note:</b> You will be redirected to the carrier’s payment portal to further process your payment.</p>
  </div>
  <div *ngIf="(state == 'LA' && data == 'Limited Liability')">
    <p>
      I understand that the insurance policy for which I am applying excludes Liability and Medical Payments to Others
      coverage for losses resulting from recreational vehicles I own. This means that the company will not pay for any
      amounts I may become liable for resulting from any recreational vehicles in my possession or owned by me.
    </p>
    <p><b>Note:</b> You will be redirected to the carrier’s payment portal to further process your payment.</p>
  </div>
  <div *ngIf="(state != 'LA' && data == 'Rental')">
    <p>
      The insurance policy for which I am applying excludes Liability and Medical Payments to Others coverage for losses
      resulting from the rental or holding for rental of any part of the insured location, regardless of the total
      annual
      compensation. This means that the company will not pay for any amounts I may become liable for resulting from the
      rental or holding for rental of any part of the insured location.
    </p>
    <p><b>Note:</b> You will be redirected to the carrier’s payment portal to further process your payment.</p>
  </div>
  <div *ngIf="(state == 'LA' && data == 'Rental')">
    <p>
      I understand the insurance policy for which I am applying excludes Liability and Medical Payments to Others
      coverage for losses resulting from the rental or holding for rental of any part of the insured location,
      regardless of the total annual compensation. This means that the company will not pay for any amounts I may become
      liable for resulting from the rental or holding for rental of any part of the insured location.
    </p>
    <p><b>Note:</b> You will be redirected to the carrier’s payment portal to further process your payment.</p>
  </div>
  <div *ngIf="data == 'Windstorm'">
    <p>We've applied automatic Windstorm Mitigation credits to your policy based on your location
      and age of
      dwelling/roof. You
      may reduce your policy premium with additional credits by submitting the Uniform Mitigation Verification
      Inspection
      Form.</p>
    <p><b>Note:</b> You will be redirected to the carrier’s payment portal to further process your payment.</p>
  </div>
</div>