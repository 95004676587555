<footer>
    <div class="footerSections">
        <div class="companyInfoSection">
            <h4>Company Info</h4>
            <ul>
                <li routerLink="/">Home</li>
                <!-- <li onclick="window.location.href='https://nsurehub.hotelplanner.com';">Travel Discounts</li> -->
                <!-- <li routerLink="/faq">FAQ</li> -->
                <li (click)="openTermsPopup();">Term and Conditions</li>
                <!-- <li routerLink="/aboutus">About Us</li>
                <li routerLink="/contacts">Contact</li> -->
                <!-- <li (click)="openPrivacyPopup();">Privacy Policy</li> -->
                <li routerLink="/florida-homeowners">Florida Homeowners</li>
                <li routerLink="/texas-homeowners">Texas Homeowners</li>
                <!-- <li routerLink="/pet-insurance">Pet Insurance</li> -->
                <li routerLink="/flood-insurance">Flood Insurance</li>
                <li routerLink="/auto-insurance">Auto Insurance</li>
                <li routerLink="/home-discounts">Insurance Discounts</li>
                <li routerLink="/life-insurance">Life Insurance</li>
                <li routerLink="/blog-insurance">Insurance Blog</li>
                <li routerLink="/credit-repair">Credit Repair</li>
                <li routerLink="/sickpay-plus">Sick Pay Plus</li>
                <li routerLink="/accident-death" title="Accident">AD&D</li>
                <li routerLink="/theft-id">ID Theft</li>
                <li routerLink="/highvalue-homes">High Value Homes</li>
                <li routerLink="/home-appliance">Home Appliance</li>
                <li routerLink="/loss-prevention">Loss Prevention</li>
            </ul>
        </div>
        <div class="companycontactSection">
            <h4>Hours of Operation</h4>
            <span class="operationTimingsLabel">Monday - Friday:9am-6pm</span>
            <ul>
                <li>
                    <mat-icon mat-dialog-close class="close">location_on</mat-icon>
                    <label>
                        <p style="font-size: 17px; margin-bottom: 0px;">801 International Parkway Suite 500</p>
                        <p style="font-size: 17px;">Lake Mary, FL 32746</p>
                    </label>
                </li>
                <li>
                    <mat-icon mat-dialog-close class="close">call</mat-icon><label><a href="tel:+18886787266">
                            1-888-678-7266</a></label>
                </li>
                <li>
                    <mat-icon mat-dialog-close class="close">mail_outline</mat-icon><label><a
                            href="mailto:info@nsurehub.com">info@nsurehub.com</a></label>
                </li>


            </ul>
        </div>
    </div>
    <div class="footerSocialMediaInfo">
        <p>Follow us on social media for expert guidance</p>
        <div class="socialLinks">
            <a target="_blank" href="https://www.youtube.com/channel/UC3BdHeWVdHIekuAiA4FEGig">
                <img src="../../../assets/images/nsurehub/youtube.png" alt="NsureHub" /></a>
            <a target="_blank" href="https://www.linkedin.com/company/nsurehub"><img
                    src="../../../assets/images/nsurehub/linkedin.png" alt="NsureHub" /></a>
            <a target="_blank" href="https://www.facebook.com/nsurehub"><img
                    src="../../../assets/images/nsurehub/facebook.png" alt="NsureHub" /></a>
            <a target="_blank" href="https://www.instagram.com/nsurehub/"><img
                    src="../../../assets/images/nsurehub/insta.png" alt="NsureHub" /></a>
            <a target="_blank" href="https://twitter.com/nsurehub"><img
                    src="../../../assets/images/nsurehub/twitter.png" alt="NsureHub" /></a>
        </div>
    </div>
    <p class="copyrightText">Copyright &copy; 2023</p>
</footer>