<app-header></app-header>
<section class="nsurehub-section" id="credit-repair-main">
  <div class="credit-repair-main_content">
    <h1>NsureHub High Value Home Policy</h1>
    <p>It’s the one investment you should get emotional about</p>
  </div>
</section>
<section class="nsurehub-section" id="theft-protection">
  <h2>Protecting all that makes your house, your home</h2>
  <p>Whether you’ve recently bought your house, or it’s been in your family for generations, we understand the care and
    attention that goes into making it your home.
  </p>
</section>
<section class="nsurehub-section" id="credit-repair-homecost">
  <div class="credit-repair-visionBox">
    <h3>Complimentary home appraisals</h3>
    <p>We don’t leave you to guess the value of your home. In most instances, we send an expert NsureHub risk consultant
      to document the intricacies of your property and precious possessions, so you know you have the coverage you need.
      If you have high value contents worth over $100,000 you will benefit from our bespoke policies.
    </p>
    <h3>Unlimited Repair Cover</h3>
    <p>We restore to the standard you expect, and never below. For most homes, we guarantee that we will repair or
      rebuild your home exactly as it was, even if the costs exceed your sum insured.
    </p>
    <h3>Take cash instead</h3>
    <p>We won’t force a course of action on you. In the event of covered total loss, if you decide not to rebuild, you
      can take an equivalent cash settlement of the value of your house.
    </p>
    <h3>A home away from home</h3>
    <p>From hotels to short-term residences, if you need a place to stay while your home is being repaired or rebuilt,
      we’ll help you find the right place based on where you’ll be most comfortable.</p>
    <h3>All your residences on one policy</h3>
    <p>Whether you own a weekend house in the country, a holiday home in France, or have an extensive worldwide property
      portfolio, we may be able to cover all your properties under one insurance policy.
    </p>
    <h3>Protection for your contents</h3>
    <p>It’s difficult to know exactly how much your contents are worth. For most homes, if the sum you’re insured for
      isn’t quite enough, we will automatically increase it by up to 25%, following approval.</p>
    <h3>Family protection</h3>
    <p>If a theft or burglary leaves you or your family disturbed we know how hard it is to carry on as normal. Our
      policy will cover the cost of any counselling to get you back to your old self and any upgrades in security that
      you may wish to do to your home for added peace of mind.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="benefits-glance">
  <div class="visionBox">
    <h3>Benefits at a glance</h3>
    <ul>
      <li>Complimentary home assessment service</li>
      <li>Unlimited repair coverage</li>
      <li>Additional living expenses covered</li>
      <li>Cash-instead option</li>
      <li>Replacement value for contents</li>
      <li>Water or oil leak coverage, including cost of loss and damage</li>
      <li>Comprehensive family protection</li>
      <li>Worldwide travel insurance</li>
      <li>Public liability and legal expense coverage</li>
      <li>Collection Risk Assessment and Valuation Review</li>
    </ul>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="about-missionVision">
  <div class="visionBox">
    <h3>Home Systems Protection</h3>
    <h4>When a breakdown happens, will your critical home equipment be covered?</h4>
    <p>Comfort. Security. Convenience. They’re what home is all about. However, most of us take the heart of our home –
      the critical equipment that provides heating, cooling, and electricity – for granted. If you are like most
      homeowners, you assume your home insurance policy, equipment warranties, and service contracts have you covered
      should critical equipment and systems in your home break down.</p>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock_1">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="theft-protection">
  <h2>Home Systems Protection</h2>
  <p>Chances are you have a gap in coverage, and a breakdown could be surprisingly costly – as much as $6,000 or more.
    Now, for covered home equipment, you can get coverage you depend on every day by enhancing your Home or Condo
    insurance policy with Home Systems Protection coverage from NsureHub.
  </p>
  <p>Today, your home equipment risks are higher.
    Today’s homes are connected. With the amount of mechanical and electric equipment found inside homes and the
    sensitive technology that drives it, breakdowns are inevitable. NsureHub Insurance's Home Systems Protection
    endorsement helps to cover the failure-incurred costs of these and more:
  </p>
  <ol>
    <li>Chair lifts and elevators</li>
    <li>Clothes washers and dryers</li>
    <li>Computer equipment</li>
    <li>Dishwashers
    <li>Electrical panels</li>
    <li>Electrical garage doors</li>
    <li>Emergency generators</li>
    <li>Heating and A/C systems</li>
    <li>Home security systems</li>
    <li>Lighting and home environment monitoring</li>
    <li>Microwaves and ovens</li>
    <li>Pool, spa and other exercise equipment</li>
    <li>Refrigerators</li>
    <li>Surround sound systems and TVs</li>
    <li>Water heaters</li>
    <li>Well-water pumps</li>
  </ol>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="home-protection">
  <div class="visionBox">
    <h3>Home Systems Protection</h3>
    <h4>Coverage helps you with:</h4>
    <ul>
      <li>Repair or necessary replacement</li>
      <li>Additional living expenses and rental value</li>
      <li>Expediting expenses</li>
      <li>Spoilage (sublimited)</li>
    </ul>
    <br>
    <p>Warranties and service contracts aren’t enough.
      Every home depends on sophisticated equipment to keep life running comfortably. Home and extended warranties may
      offer homeowners a sense of security, but the protection they offer can be very limited and expensive. Home and
      extended warranties are intended for everyday maintenance. With NsureHub Insurance’s Home Systems Protection
      coverage, you can rest easy knowing the cost of breakdowns are covered, and life can go on comfortably.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock_1">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="theft-protection">
  <h2>IDENTITY THEFT PROTECTION PLAN</h2>
  <p>Identity Theft is the fastest growing crime in America. In a matter of seconds, personal information such as a
    social security number, a credit card number and/or an address can be stolen; leaving individuals to unravel the
    financial mess created by an identity thief.</p>

  <p>Members will have 24/7 access to Identity Theft Restoration Advocates who will provide them with comprehensive,
    personalized recovery services.</p>

  <p>If a member becomes a victim of identity theft, NsureHub assigns a Recovery Advocate to manage the case and
    activate
    the members of our Recovery Team (fraud investigators, legal counsel, and other experts) needed to help with the
    recovery process from beginning to end.</p>
  <p>
    This includes:
  </p>
  <ol>
    <li>Consult with the covered member to ascertain the severity of the theft and start the notification process.</li>
    <li>Prepare and send the customized precompleted, state specific “ID Recovery Kit™” to member via Federal Express
      overnight.
    </li>
    <li>Contact all three credit reporting agencies to obtain a free credit report for the covered member, place fraud
      alerts on the covered member’s credit records, and obtain a list of creditors involved with the fraud.
    </li>
    <li>Notify the appropriate bank or agency if other forms of identification such as an ATM card, driver’s license,
      social security card, or passport were stolen or are missing.</li>
    <li>Provide fraud resolution, legal and emotional assistance from beginning to end.</li>
  </ol>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="about-footer">
  <div class="about-footer_content">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>