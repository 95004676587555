import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-animal-libility-confirm',
  templateUrl: './animal-libility-confirm.component.html',
  styleUrls: ['./animal-libility-confirm.component.scss']
})
export class AnimalLibilityConfirmComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<AnimalLibilityConfirmComponent>) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close(false);
  }
  next(){
    this.dialogRef.close(true);
  }

}
