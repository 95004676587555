<div class="center_img">
    <img (click)="closePopup()" src="../../../assets/images/Vector.png" alt="NsureHub">
</div>
<div class="kickoutScreen">
    <img src="../../assets/images/nsurehub/logo.png" alt="NsureHub"/>
    <p>
        Based on National Hurricane Center's predicted path of Tropical Storm Nicole, 
        effective today, Tuesday, October 8, 2022 at 09:00 AM EST, 08:00 AM CST, binding restrictions are 
        instituted for the state of Florida.
    </p>
    <p>The cease to bind applies to NsureHub new business as well as increases in limits, increases in coverage and deductible changes for all policy forms. </p>
    <p>Please contact us at:
        <a href="tel:+18886787266">1-888-678-7266</a> or revisit the site after the storm has passed the State of Florida.
    </p>
</div>