import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-kick-out-screen-storm',
  templateUrl: './kick-out-screen-storm.component.html',
  styleUrls: ['./kick-out-screen-storm.component.scss']
})
export class KickOutScreenStormComponent implements OnInit {

  constructor( private dialogRef:MatDialogRef<KickOutScreenStormComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("errordata=>",data);
   }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close(); 
  }

}
