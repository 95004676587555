<app-header></app-header>
<section class="nsurehub-section" id="home-discount-main">
  <div class="home-discount-main_content">
    <h1>NsureHub Smart Home Discounts</h1>
    <p>Safe, Secure, Smart Home Discount</p>
    <p>Your smart home is where your dreams grow, so it's important you keep it protected - we'll reward you for taking
      the right steps. In facts, if you've got a smart home device or home security system, you can qualify for savings
      on your policy.
    </p>
  </div>
</section>

<section class="nsurehub-section" id="home-discount-corevalues">
  <h2>WE BELIEVE A SMART HOME IS A SAFER HOME.</h2>
  <p>Click the Icon below to Receive Discounts on Both Systems. NsureHub customers receive upto 20% discounts on Home
    Systems. Also, receive up to 15% discount on your Home Insurance Policy
  </p>
  <div class="coreValuesList">
    <div class="coreValue">
      <img src="../../assets/images/home-discounts/Home security.png" alt="NsureHub" />
      <h1>Home Security</h1>
      <button class="button-fill"
        onclick="window.location.href='https://www.jetsonsliving.com/video-service-solutions/';">Click to
        Purchase
      </button>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/home-discounts/Vector.png" alt="NsureHub" />
      <h1>Water Detection</h1>
      <button class="button-fill" onclick="window.location.href='https://getguardian.com/pages/nsure';">Click to
        Purchase
      </button>
    </div>
  </div>
</section>

<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" onclick="window.location.href='https://getguardian.com/pages/nsure';">Get
      Discount
    </button>
  </div>
</section>

<section class="nsurehub-section" id="home-discount--bg-imagesection">
  <div class="home-discount--bg-imagesectionContent">
    <h1>Home Security
      <p>When your old video surveillance system meets AI smart technology, you get limitless possibilities and powerful
        peace of mind.
      </p>
    </h1>
    <br>
    <br>
    <br>
    <section class="nsurehub-section" id="about-video">
      <video controls poster>
        <source src="../../assets/videos/home-security.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </section>
    <br>
    <h3>Home Security System – How it works</h3>
  </div>
</section>

<section class="nsurehub-section" id="home-discount-wherewedoBusiness">
  <div class="wherewedoBusiness-right">
    <h1>Customized Needs</h1>
    <br>
    <p>Not every client has the same needs, so we customize each system to solve their unique issues. Jetsons Living
      provides tailored solutions for clients and installers.
    </p>
    <p>
      Simply give us a call, tell us what your needs are and we will make an AI surveillance system you will love.
    </p>
    <p>
      Uniquely we provide a step-by-step process for Installers and Customers. This simplifies the buying and
      installation process as we do all the hard stuff for you.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='https://www.jetsonsliving.com/video-service-solutions/';">Get
      Discount
    </button>
  </div>
</section>
<section class="nsurehub-section" id="home-discount-smart-home-things">
  <div class="smart-home-things-right">
    <img src="../../assets/images/home-discounts/smart_logo.png" alt="NsureHub" />
    <h1>What A Smart Home Looks & Feels Like</h1>
    <img src="../../assets/images/home-discounts/smart_icon.png" alt="NsureHub" />
  </div>
</section>
<section class="nsurehub-section" id="water-damage">
  <div class="water-damage-content">
    <h1>21st Century Water Damage Prevention.</h1>
    <br>
    <p>We invented Guardian after experiencing the devastating effects of water damage first hand and growing frustrated
      with the lack of smart leak prevention solutions on the market. Whatever did exist required professional
      installation or thousands of dollars in investment, making them inappropriate for most homeowners. Not anymore.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="insuranceNeed-features">
  <div class="insurancefeaturesList">
    <div class="insurancefeature">
      <div class="insurancefeatureBox">
        <img src="../../assets/images/home-discounts/tools.png" alt="NsureHub" />
        <h4>No Tools Required</h4>
        <p>Install Guardian without any tools or cutting into pipes
        </p>
      </div>
    </div>
    <div class="insurancefeature">
      <div class="insurancefeatureBox">
        <img src="../../assets/images/home-discounts/battery.png" alt="NsureHub" />
        <h4>BATTERY BACKUP
        </h4>
        <p>Guardian can run for up to 12 hours on the optional battery backup
        </p>
      </div>
    </div>
    <div class="insurancefeature">
      <div class="insurancefeatureBox">
        <img src="../../assets/images/home-discounts/extrem.png" alt="NsureHub" />
        <h4>EXTREME LONG RANGE
        </h4>
        <p>Leak Detectors can be placed up to 1,000 from the Valve Controller</p>
      </div>
    </div>
    <div class="insurancefeature">
      <div class="insurancefeatureBox">
        <img src="../../assets/images/home-discounts/standalone.png" alt="NsureHub" />
        <h4>COMPLETELY STANDALONE
        </h4>
        <p>No hubs or other 3rd party products required
        </p>
      </div>
    </div>
    <div class="insurancefeature">
      <div class="insurancefeatureBox">
        <img src="../../assets/images/home-discounts/offline.png" alt="NsureHub" />
        <h4>OFFLINE FUNCTIONALITY
        </h4>
        <p>Guardian will shut off your water during leaks even without Wi-Fi</p>
      </div>
    </div>
    <div class="insurancefeature">
      <div class="insurancefeatureBox">
        <img src="../../assets/images/home-discounts/expand.png" alt="NsureHub" />
        <h4>EXPAND YOUR PROTECTION
        </h4>
        <p>Add Leak Detectors + accessories around your home
        </p>
      </div>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='https://www.jetsonsliving.com/video-service-solutions/';">Get
      Discount
    </button>
  </div>
</section>
<section class="nsurehub-section" id="protect-investment">
  <div class="protect-investment-content">
    <h1>Protect your investment.</h1>
    <p>Guardian was designed from the ground up to allow all home and property owners to protect themselves from water
      damage. It's easy to install, quick to act, and capable of stopping most types of plumbing leaks dead in its
      tracks.
    </p>
    <img src="../../assets/images/home-discounts/image.png" alt="NsureHub" />
  </div>
  <!-- <img src="../../assets/images/home-discounts/smart_logo.png" alt="NsureHub" /> -->
  <div class="protect-investment-feature">
    <div class="protect-investment-feature-list">
      <img src="../../assets/images/home-discounts/remotly.png" alt="NsureHub" />
      <h1>CONTROL REMOTELY</h1>
      <p>Open and close your water valve and check the status of your sensors from anywhere</p>
    </div>
    <div class="protect-investment-feature-list">
      <img src="../../assets/images/home-discounts/Vector-1.png" alt="NsureHub" />
      <h1>AUTOMATIC SHUT-OFF</h1>
      <p>Guardian will automatically shut off your water supply if a leak is detected at your property</p>
    </div>
  </div>
  <section class="nsurehub-section" id="about-video">
    <video controls poster>
      <source src="../../assets/videos/Guardian.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </section>
  <h3>Leak Detection System – How easy to install</h3>
</section>
<section class="nsurehub-section" id="home-discount-wherewedoBusiness">
  <div class="wherewedoBusiness-right">
    <h1>Enjoy Safe Living With Peace Of Mind.</h1>
    <br>
    <p>Guardian is a system of Leak Detectors and a Valve Controller that work together to detect leaks and shut off
      your water automatically, preventing further damage. Guardian installs with no tools and no pipe cutting – simply
      install, connect, and protect.
    </p>
    <p>Anyone can install Guardian out of the box, but first, it's important to make sure Guardian is compatible with
      your plumbing.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='https://www.jetsonsliving.com/video-service-solutions/';">Get
      Discount
    </button>
  </div>
</section>

<section class="nsurehub-section" id="home-discount-imagesection_1">
  <div class="home-discount-imagesectionContent_1">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>