<app-header></app-header>
<section class="step2Container">
    <div class="stepcontent">
        <img src="../../../../../assets/images/nsurehub/congratulationsImg.png" alt="NsureHub Congratulations">
        <h1>CONGRATULATIONS!</h1>
        <p>{{localDCResponse}} your
            policy ({{policyNumber}}) is bound. We have sent your application to your email for signature. Please sign
            your

            application to avoid cancellation of this policy.

        </p>
        <button class="button-fill" (click)="home();">Back to Home</button>
    </div>
</section>
<!-- Conversion Pixel - FLORIDA NsureHub Awareness/ Acquisition - DO NOT MODIFY -->
<script src="https://secure.adnxs.com/px?id=1596365&t=1" type="text/javascript"></script>
<!-- End of Conversion Pixel -->
<app-footer></app-footer>