<div class="popup">
  <p class="content">
    Any of the following animals owed by you or kept on premises: exotic animals, or animals with vicious/dangerous
    tendencies or any animals with bit history or any of the following dog breeds: Chow Chows, Doberman Pinchers, German
    Shepherds, Great Danes, Keeshonds, Pit-Bull, Pit-Bull mixes, Presa Canarios, Rottweilers, Staffordshire Terriers,
    Wolf, Wolf Hybrid any mix of these breeds. Dogs with bite history are not eligible for coverage.
  </p>
  <div class="buttons">
    <button class="button-fill button-fill-no" (click)="cancel()">NO</button>
    <button class="button-fill button-fill-yes" (click)="goToNextStep()">Yes</button>
  </div>
</div>