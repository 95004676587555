import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyComponent } from 'src/app/privacy-policy/privacy-policy.component';
import { TermComponent } from 'src/app/term/term.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openTermsPopup(){
    this.dialog.open(TermComponent, {
      height: "80vh"
    });
  }
  openPrivacyPopup(){
    this.dialog.open(PrivacyPolicyComponent, {
      height: "80vh"
    });
  }

}
