<div class="center_img">
    <img (click)="closePopup()" src="../../../assets/images/Vector.png" alt="NsureHub">
</div>
<div class="newsPartnerContainer">
    <h2>Inspection Promotion Terms and Conditions</h2>

    <div class="newsPartnercontent">
        <p>Subject to the limitations as contained under Section 626.9541(1)(m), the Florida Statutes or any other
            applicable law enforced for the time being, the Company may, in its sole discretion, offer to an insured or
            prospective insured, a Store Gift Card equivalent to the value of $50 or any other amount subject to a
            maximum limit of $100 per year, which may be used by such insured or prospective insured to get from the
            Company, a Home Inspection Service or any other loss mitigation service as may be offered by the Company
            from time to time. The Store Gift Card shall only be allowed to be redeemed within a period of 90
            days/months for the above-mentioned purpose and the same shall not be allowed to be encashed or exchanged in
            any other manner whatsoever. Moreover, the Store Gift Card shall not be construed or deemed as an Unlawful
            Rebate as provided under Section 626.572, the Florida Statutes, nor shall it be considered as an inducement
            or investment of any form. The Company disclaims any liability that may arise as a result of relying on the
            opinion given by a representative or agent of the Company during or after the Home Inspection Service or any
            other loss mitigation service. The Company reserves the right to discontinue issuing Store Gift Cards any
            time without notice or intimation.</p>
        <p>This Disclaimer is signed between the NsureHub Insurance Agency and the Client. The client by reading this
            disclaimer acknowledge and agree that;</p>
        <p>I ACKNOWLEDGE that NsureHub Insurance Agency under their legal right is offering a promotional gift or rebate
            of $50 towards anyone who has to get a home inspection to get insurance.</p>
        <p>I ACKNOWLEDGE I am voluntarily participating in the Promotional Gift offer/Rebate of the NsureHub Insurance
            Agency. I further acknowledge that “Insurance advertising gifts lawfully permitted under s. 626.9541(1)(m)."
            State v. Rubio, 917 So. 2d 383, 393 (Fla. Dist. Ct. App. 2005). “Section 626.572 appears to allow agents to
            lawfully rebate a portion of their commission if the rebate satisfies the criteria set forth under that
            section; however, subsection (1)(d) allows an insurer to prohibit their agents from rebating commissions."
            Butler v. State, Dept. of Ins. Co., 680 So. 2d 1103, 1105-06 (Fla. Dist. Ct. App. 1996)</p>
        <p>THEREFORE I HEREBY, for myself, my heirs, executors, administrators, assigns, or personal representative
            (hereinafter collectively, "Release," "I"' or "me", which terms shall also include Releasor's parents or
            guardians if Releasor is under 18 year of age), knowingly and voluntarily enter into this DISCLAIMER and
            hereby waive all rights, claims or causes of action of any kind arising out of my participation in the said
            activity with NsureHub Insurance Agency. </p>
        <p>I AGREED TO RELEASE NsureHub Insurance Agency its agents, servants, employees, insurers, successors and
            assigns from any and all claims, demands, causes of action, damages or suits at law and equity of any kind,
            including but not limited to claims for unlawful practice, property damage, medical expenses, loss of
            services, or any claim related to the said participation with NsureHub Insurance Agency. </p>
        <p>I FURTHER AGREE to indemnify, defend and hold harmless the Releases against any and all claims, suits, or
            actions of any kind whatsoever for liability, damages, compensation, or otherwise brought by me or anyone on
            my behalf, including attorney's fees and any related costs.</p>
        <p>The undersigned has read this DISCLAIMER in its entirety and voluntarily signs this document knowing that it
            constitutes a waiver of liability. ALL CARE IS TAKEN IN THE PREPARATION OF THE INFORMATION AND PUBLISHED
            MATERIALS ON THIS SITE. NSUREHUB INSURANCE AGENCY (WE) DOES NOT MAKE ANY REPRESENTATIONS OR GIVE ANY
            WARRANTIES ABOUT ITS ACCURACY, RELIABILITY, COMPLETENESS OR SUITABILITY FOR ANY PARTICULAR PURPOSE. TO THE
            EXTENT PERMISSIBLE BY LAW, WE WANT TO GIVE $50 TOWARDS ANYONE WHO MIGHT TO GET A HOME INSPECTION TO GET
            INSURANCE SECTION 626.9541(1)(M), FLORIDA STATUTES, BY ALLOWING INSURERS AND AGENTS TO GIVE ADVERTISING AND
            PROMOTIONAL GIFTS HAVING A TOTAL VALUE OF NOT MORE THAN $100 PER INSURED OR PROSPECTIVE INSURED IN ANY
            CALENDAR YEAR. NSUREHUB DO NOT NECESSARILY APPROVE OF, ENDORSE, OR SPONSOR ANY CONTENT OR MATERIAL ON SUCH
            SITES. TO THE COMPANY'S KNOWLEDGE, NO DIRECTOR, OFFICER, KEY EMPLOYEE OR AGENT OF THE COMPANY IS SUBJECT TO
            ANY CONFIDENTIALITY, NON-DISCLOSURE, NON-COMPETITION AGREEMENT OR NON-SOLICITATION AGREEMENT WITH ANY
            EMPLOYER OR PRIOR EMPLOYER THAT COULD REASONABLY BE EXPECTED TO MATERIALLY AFFECT HIS ABILITY TO BE AND ACT
            IN HIS RESPECTIVE CAPACITY OF THE COMPANY OR BE EXPECTED TO RESULT IN A MATERIAL ADVERSE CHANGE. UNDER NO
            CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
            THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. THIS $50 OFFERS WILL BE CONSIDERED
            AS PERMITTED AS ADVERTISING AND PROMOTIONAL GIFT UNDER SECTION 626.9541(1)(M) OF FLORIDA'S UNFAIR INSURANCE
            TRADE PRACTICES ACT. </p>
        <p>YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK. </p>
        <p>IF YOU HAVE ANY CONCERNS REGARDING THE CONTENT OF THE WEBSITE, PLEASE CONTACT NSUREHUB INSURANCE AGENCY.</p>
    </div>
</div>