import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../services/app.service';
import { TransactionRq } from '../shared/transactionRq.model';
import { KickOutScreenComponent } from '../kick-out-screen/kick-out-screen.component';
import { MatDialog } from '@angular/material/dialog';
//declare let ga: Function;
@Component({
  selector: 'app-payment-process-aeg',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.css']
})
export class PaymentProcessAEGComponent implements OnInit, AfterViewInit {

  constructor(public router: Router, private dialog: MatDialog, public dataService: AppService, private cdr: ChangeDetectorRef) {
  }

  public emailID: string = "";
  public x_response_code = "";
  public paymentError: boolean = false;
  public transactionRq: TransactionRq = new TransactionRq();
  public transactionRs: any;
  ngOnInit() {
    this.setTransactionRq();
  }

  ngAfterViewInit() {
    if (this.transactionRq.Responsecode == "Approved") {
      this.dataService.validatePayment(this.transactionRq).subscribe(
        (data: any) => {
          this.transactionRs = data;
          if (data.isPolicyAttachedtoTxId && data.postedToBilling && !data.Error) {
            sessionStorage.setItem('policyNumber', data.policyNumber);
            this.router.navigate(['/payment-success']);
          } else {
            this.paymentError = true;
            this.dialog.open(KickOutScreenComponent, {
              id: "kickOutPopup",
              height: "40vh",
              data: {
                source: "BindQuote",
                details: "412 (Precondition failed)"
              }
            });
          }

        },
        (error: any) => {
          this.paymentError = true;
          this.dialog.open(KickOutScreenComponent, {
            id: "kickOutPopup",
            height: "40vh",
            data: {
              source: "BindQuote",
              details: "412 (Precondition failed)"
            }
          });
        }
      )
    } else {
      this.paymentError = true;
      this.cdr.detectChanges();
      this.dialog.open(KickOutScreenComponent, {
        id: "kickOutPopup",
        height: "40vh",
        data: {
          source: "Payment Failed",
          details: "Card Authorization Failed"
        }
      });
    }
  }

  getUrlParameter(name: any) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  setTransactionRq() {
    this.transactionRq.Responsecode = this.getUrlParameter('TransactionStatus');
    this.transactionRq.TransactionId = this.getUrlParameter('TransactionId');
    this.transactionRq.EmailId = sessionStorage.getItem("emailID") || '';
    this.transactionRq.QuoteNumber = sessionStorage.getItem("quote_number") || '';
    this.transactionRq.IsMortgage = sessionStorage.getItem("isMortgage") || '';
    this.transactionRq.State = localStorage.getItem("DC_USER_STATE") || '';
  }

  goHome() {
    window.location.href = "/";
  }
}
