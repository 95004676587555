import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-highvalue-homes',
  templateUrl: './highvalue-homes.component.html',
  styleUrls: ['./highvalue-homes.component.scss']
})
export class HighvalueHomesComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
  }

}
