<app-header></app-header>
<section class="nsurehub-section" id="about-main">
  <div class="about-main_content">
    <h1>Protecting your tomorrow, today!</h1>
    <p> We never know what card we are going to be dealt next in life, do we?</p>

    <p>From our home and automobiles to our business, all are at risk in an uncertain, ever-changing world.</p>

    <p>This is where the right insurance can help you safeguard your present and future, empowering you to realize your
      dreams.</p>
  </div>
</section>

<section class="nsurehub-section" id="about-wherewedoBusiness">
  <div class="wherewedoBusiness-left">
    <img src="../../assets/images/nsurehub/wherewedobusiness.png" alt="NsureHub" />
  </div>
  <div class="wherewedoBusiness-right">
    <h2>Where we do business</h2>
    <p>NsureHub currently operates in Florida, Texas, and Louisiana. Homeowner's Quotes are available online for
      Florida, Texas, and Louisiana.</p>
  </div>
</section>

<section class="nsurehub-section" id="about-atglance">
  <h2>SafePoint/NsureHub At A Glance</h2>
  <div class="glance-content">
    <div class="glance-tab">
      <h4>10+</h4>
      <p>Years of <br>Experience</p>
    </div>
    <div class="glance-tab">
      <h4>$1000</h4>
      <p>Average Savings <br>for our clients</p>
    </div>
    <div class="glance-tab">
      <h4>A</h4>
      <p>Rating</p>
    </div>
    <div class="glance-tab">
      <h4>101K+</h4>
      <p>Insurance <br>Policies Bound</p>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="about-corevalues">
  <h2>Everything we do at NsureHub is shaped <br>by our core values of…</h2>
  <div class="coreValuesList">
    <div class="coreValue">
      <img src="../../assets/images/nsurehub/trust.png" alt="NsureHub" />
      <label>Trust</label>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/nsurehub/relationship.png" alt="NsureHub" />
      <label>Building <br>Relationship</label>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/nsurehub/empathy.png" alt="NsureHub" />
      <label>Empathy</label>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/nsurehub/innovation.png" alt="NsureHub" />
      <label>Innovation</label>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/nsurehub/sustain.png" alt="NsureHub" />
      <label>Sustainability</label>
    </div>
    <div class="coreValue">
      <img src="../../assets/images/nsurehub/centricity.png" alt="NsureHub" />
      <label>Client Centricity</label>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="about-redefining">
  <div class="about-redefining_content">
    <h1>NsureHub: Redefining the insurance <br>industry, one policy at a time.</h1>
    <p>NsureHub is a team of insurance and technology geeks that want to fix the entire insurance industry - starting
      with the time-consuming and confusing sales process.</p>

    <p>Leveraging cutting-edge technology and upholding the trust our customers place in us, we cater to all our
      customers’
      insurance needs so they can buy the insurance they want quickly, easily, and confidently in a one-step hassle-free
      process.</p>

    <p>Our wide range of insurance product offerings, impeccable service, enhanced capabilities, direct-to- consumer
      platform partnerships, experienced team, and simple and easy process make us the go-to choice of all.</p>
  </div>
</section>
<section class="nsurehub-section" id="about-video">
  <video controls poster="../../assets/images/nsureHubVideoBack.png">
    <source src="../../assets/videos/aboutusvideo.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</section>
<section class="nsurehub-section" id="about-missionVision">
  <div class="ourMission visionBox">
    <h3>Our Mission</h3>
    <p>To offer an innovative, transparent, and hassle- free way for customers to buy their home insurance with
      confidence.</p>
  </div>
  <div class="ourVision visionBox">
    <h3>Our Vision</h3>
    <p>Become the #1 easy and affordable home insurance provider, helping people live a happy and healthy life – on
      their own terms.</p>
  </div>
</section>
<section class="nsurehub-section" id="about-newspartners" *ngIf="false">
  <h2>NsureHub News and Partners</h2>
  <p>SafePoint is proud to be rated favorably by leading independent rating organizations. These companies specialize in
    reviewing, analyzing and attesting to insure companies’ financial security and their ability to deliver on promises
    made to policyholders.</p>
  <h3>Demotech Rating</h3>
  <p><i>Demotech has assigned the rating of A Exceptional to SafePoint</i></p>
  <p>This Financial stability rating is an indication of an insurance company’s financial strength.</p>
  <p>According to Demotech, an A rating means Safepoint has an Exceptional ability to maintain liquidity of invested
    assets, quality reinsurance, acceptable financial leverage and realistic pricing while simultaneously establishing
    loss and loss adjustment expense reserves at reasonable levels.</p>
  <p>For details, <a class="linkgreen" target="_blank" href="https://www.demotech.com/">Visit Demotech on-line here.</a>
  </p>

  <div class="readmoreBlocks">
    <div class="readMoreBlock">
      <p>NsureHub Innovates Again and is Leading the Charge in Digital.....</p>
      <label><a target="_blank"
          href="https://www.abnewswire.com/pressreleases/nsurehub-innovates-again-and-is-leading-the-charge-in-digital-transformation-in-the-home-insurance-space_613973.html">Read
          More</a></label>
    </div>
    <div class="readMoreBlock">
      <p>NsureHub Offers Innovative, Transparent And Hassle-Free Way.....</p>
      <label><a target="_blank" href="https://infutor.com/resource/cs-nsurehub/">Read More</a></label>
    </div>
  </div>
  <div class="partnersList">
    <img src="../../assets/images/nsurehub/aggne.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/Figo.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/Progressive.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/verisk-logo1.svg" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/coherent.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/aws.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/gic.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/Jetsons-Living-Logo-1.webp" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/gaju.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/safepoint.png" alt="NsureHub" />
    <img src="../../assets/images/nsurehub/guardian.png" alt="NsureHub" />
  </div>
  <div class="partnersButtonContainer">
    <button class="button-fill" (click)="openNewsPartnerBox()">Learn about NsureHub Partners</button>
  </div>
</section>
<section class="nsurehub-section" id="about-footer">
  <div class="about-footer_content">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>