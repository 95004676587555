import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  localDCResponse:any;
  policyNumber:any;
  constructor(public router: Router) { }

  ngOnInit() {
    this.localDCResponse = sessionStorage.getItem('userFullName');
    this.policyNumber =  sessionStorage.getItem('policyNumber');
  }

  home() {
    window.location.href = "/";
  }
}

