import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function () {
      var silder = $(".owl-carousel");
      silder.owlCarousel({
          autoplay: true,
          autoplayTimeout: 3000,
          autoplayHoverPause: false,
          items: 3,
          stagePadding: 20,
          center: true,
          nav: true,
          margin: 70,
          dots: false,
          loop: true,
          navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
          responsive: {
              0: { items: 1 },
              480: { items: 2 },
              575: { items: 2 },
              768: { items: 2 },
              991: { items: 3 },
              1200: { items: 4 }
          }
      });
  });
  }

}
