import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { switchMap, filter, take, catchError, tap } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppService } from '../services/app.service';

@Injectable()
export class NsureInterceptor implements HttpInterceptor {
    constructor(private ngxService: NgxUiLoaderService, private appService: AppService) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (request.url.includes('maps.googleapis')) {
            this.ngxService.stopLoader('master');
        } else if (request.url.includes('Nsurehub')) {
            request = this.addToken(request);
        }

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (event.body.Error && !request.url.includes('SetReport')) {
                        this.appService.setReport(event.body.Error).subscribe((res: any) => { });
                    }
                }
            }), catchError((error: any) => {
                if ((error instanceof HttpErrorResponse && error.status === 401) || !error) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(() => { return error });
                }
            }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        // window.location.href = '/#/login';
        return throwError(() => { return "No Login" });
    }

    domain_from_url(url:any) {
        var result
        var match
        if (match = url.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/im)) {
          result = match[1];
        }
        return result
      }

    private addToken(request: HttpRequest<any>) {
        let domain = this.domain_from_url(window.location.origin);
        if ((domain == 'nsurehub.com') && request.url.includes('InfutorAPI')) {
            return request.clone({
                setHeaders: {
                    'Apikey': 'Qw7eR1TdUb9LoXc0VfA3zGpJm6N8kWi'
                }
            });
        } else {
            return request.clone({
                setHeaders: {
                    'XAPIKEY': this.appService.getEnvironment().XApiKey
                }
            });
        }
    }
}