import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-howto-video',
  templateUrl: './howto-video.component.html',
  styleUrls: ['./howto-video.component.scss']
})
export class HowtoVideoComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<HowtoVideoComponent>) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close(); 
  }
}
