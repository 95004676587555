<app-header></app-header>
<section class="nsurehub-section" id="about-main">
  <div class="about-main_content">
    <h1>Why Choose the NsureHub Pet Insurance Plan for Your Pet?</h1>
    <p>Everything we do is driven from our love of animals and our commitment to pet health. We offer one
      budget-friendly pet insurance plan that covers vet visits for new accidents and illnesses. It makes it easy to
      help protect you and your pet with a pet insurance plan this easy to understand.</p>
  </div>
</section>
<section class="nsurehub-section" id="about-wherewedoBusiness">
  <div class="wherewedoBusiness-right">
    <h2>Help! My Dog Keeps Humping: Reasons & Solutions</h2>
    <p>Although humping in dogs can sometimes be sexual in nature, that isn’t always the reason. Luckily, there are
      several ways to discourage your dog from doing "the deed". Here’s why your dog might be inclined to hump, and tips
      to stop the embarassing habit.</p>
  </div>
  <div class="wherewedoBusiness-left">
    <img src="../../assets/images/pet-anusha.png" alt="NsureHub" />
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='https://figopetinsurance.com/?p=9e9T0V7O7s7#get-a-quote';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="credit-repair-rates">
  <h2>NsureHub Designed for Pets & Their People</h2>
  <p>Being a pet parent comes with a lot of joy, and some unavoidable realities. But pet insurance — an enhancement to
    your pet’s wellness, your peace of mind, and your bank account — shouldn’t add any unexpected twists or turns.
  </p>
  <p>Founded by pet parents unable to find a pet insurance option that fit our diverse lifestyles, NsureHub was born.
    Our
    innovative experience is designed around real people and real scenarios, taking insurance out of its overly complex
    & unrelatable world and into the homes of everyday pet parents.
  </p>
  <p>With less barriers, and added benefits that go beyond insurance, NsureHub fits into your lives, not the other way
    around.
  </p>
</section>
<section class="nsurehub-section" id="pet-imagesection">
  <div class="pet-imagesectionContent">
    <h3>Nsurehub Pet Insurance: Designed for Pets and their People.</h3>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='https://figopetinsurance.com/?p=9e9T0V7O7s7#get-a-quote';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="covered-features">
  <h2>Our Values</h2>
  <div class="featuresList">
    <div class="feature">
      <img src="../../assets/images/pet-icon/caps.png" alt="NsureHub" />
      <h4>No Per Incident Caps</h4>
      <p>We don’t cap claaim payments by condition type, so you can maake the most of your annuaal benefits.</p>
    </div>
    <div class="feature">
      <img src="../../assets/images/pet-icon/cover.png" alt="NsureHub" />
      <h4>Coverage for Curable Pre-Existing Conditions</h4>
      <p>We may cover pre-existing conditions considered curable if they show no signs or symptoms within 12 months of
        treatment.
      </p>
    </div>
    <div class="feature">
      <img src="../../assets/images/pet-icon/fast.png" alt="NsureHub" />
      <h4>Lightning Fast Claims</h4>
      <p>Less waiting, $$ quicker than ever. On average, most claims close in just 2.6 working days.
      </p>
    </div>
    <div class="feature">
      <img src="../../assets/images/pet-icon/power.png" alt="NsureHub" />
      <h4>Wellness Powerups</h4>
      <p>Stay on top of yearly exams & more with preventative coverages.</p>
    </div>
    <div class="feature">
      <img src="../../assets/images/pet-icon/live.png" alt="NsureHub" />
      <h4>Live Vet</h4>
      <p>Free 24/7 access to a licensed veterinary professional. Here for you during emergencies, no matter when they
        happen.
      </p>
    </div>
    <div class="feature">
      <img src="../../assets/images/pet-icon/tag.png" alt="NsureHub" />
      <h4>Personalized Per Tag</h4>
      <p>Synced to your Pet Cloud. Helps reunite you and your pet if they go missing.</p>
    </div>
  </div>
</section>
<br>
<br>
<br>
<section class="nsurehub-section" id="credit-repair-rates">
  <h2>We are Pet-Forward Insurance Experts</h2>
  <p>Our love for pets is immeasurable and celebrated across our diverse and dynamic NsureHub team.</p>
  <p>Unlike some competitors, every member of our US-based customer experience team is P&C licensed, so they are
    qualified to give expert advice on which policy is right for you and your pet.
  </p>
  <p> And as you may have guessed, we are all obsessed with our pets.</p>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='https://figopetinsurance.com/?p=9e9T0V7O7s7#get-a-quote';">Get a
      Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="pet-imagesection_1">
  <div class="pet-imagesectionContent_1">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>