import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IframeKickoutComponent } from 'src/app/iframe-kickout/iframe-kickout.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isQuoteHeader: boolean = false;
  currentPath: string = "";
  localDCResponse:any;
  propAddress:any;
  showMenu:boolean = false;
  showMobileMenu:boolean = false;

  constructor(private router: Router, public appService: AppService,private dialog:MatDialog) {
    this.currentPath = this.router.url;
    this.localDCResponse = localStorage.getItem("DCJSONLOCAL");
    this.localDCResponse = JSON.parse(this.localDCResponse);
    this.propAddress = this.localDCResponse&&this.localDCResponse.AccountDetails&&this.localDCResponse.AccountDetails.PropertyAddress.Address1&&this.localDCResponse.AccountDetails.PropertyAddress.Address1+", "+this.localDCResponse.AccountDetails.PropertyAddress.City+", "+this.localDCResponse.AccountDetails.PropertyAddress.State+", "+this.localDCResponse.AccountDetails.PropertyAddress.ZipCode;
    if (this.appService.currentStep>0) {
      this.isQuoteHeader = true;
      
    }
  }

  ngOnInit(): void {
  }
  goToHomePage1(){
    this.router.navigate(['']);
  }
  goToHomePage() {
    this.router.navigate(['']);
    this.appService.currentStep = 2;
  }
  stepClicked(stepID:number){
    if(this.appService.currentStep>stepID){
      if(stepID==1){
        this.goToHomePage();
      }else{
        this.appService.currentStep = stepID;
      }
      
    }
  }

  openLink(){
    this.dialog.open(IframeKickoutComponent, {
      width: "80vw",
      height: "80vh",
      panelClass: 'my-full-screen-dialog',
      data: { frameUrl : 'https://www.compulife.net/website/2657/quoter.html'}
    });
  }
}
