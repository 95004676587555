import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-florida-homeowners',
  templateUrl: './florida-homeowners.component.html',
  styleUrls: ['./florida-homeowners.component.scss']
})
export class FloridaHomeownersComponent implements OnInit {

  constructor(private meta: Meta, private router: Router) {
    this.meta.updateTag({
      content: `Extreme weather, susceptibility to natural disasters, and risk to home life make home
    insurance a must-have in Florida. Get your home insurance policy with NsureHub today!`}, 'name="description"');
    this.meta.updateTag({ content: `Florida Homeowners Insurance | NsureHub` }, 'name="title"')
  }

  ngOnInit(): void {
  }
  
  goToHome() {
    this.router.navigate(['/']);
  }
}
