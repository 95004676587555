import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-texas-homeowners',
  templateUrl: './texas-homeowners.component.html',
  styleUrls: ['./texas-homeowners.component.scss']
})
export class TexasHomeownersComponent implements OnInit {

  constructor(private meta: Meta,private router:Router) {
    this.meta.updateTag({ content: `Want to make an informed decision about your Texas home insurance policy? Here’s
    all you need to know about home insurance in Texas. Get your home insurance quote now!`} , 'name="description"' );
    this.meta.updateTag({ content: `Texas Homeowners Insurance | NsureHub`} , 'name="title"' )

   }

  ngOnInit(): void {
  }
  goToHome(){
    this.router.navigate(['/']);
      }

}
