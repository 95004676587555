<div class="mainContainer">
    <img (click)="closePopup()" src="../../../../../../assets/images/Vector.png" alt="NsureHub">
    <div class="contant">
        <p>
            Any of the following animals owed by you or kept on premises: exotic animals, or animals with
            vicious/dangerous tendencies or any animals with bit history or any of the following dog breeds: Chow Chows,
            Doberman Pinchers, German Shepherds, Great Danes, Keeshonds, Pit-Bull, Pit-Bull mixes, Presa Canarios,
            Rottweilers, Staffordshire Terriers, Wolf, Wolf Hybrid any mix of these breeds. Dogs with bite history are
            not eligible for coverage.
        </p>
    </div>
    <div class="buttonContainer">
        <button class="button-fill" (click)="next()">
            Continue
        </button>
    </div>
</div>