<app-header></app-header>
<section class="nsurehub-section" id="credit-repair-main">
  <div class="credit-repair-main_content">
    <form (submit)="submit()">
      <div class="container mt-5 mb-5 d-flex justify-content-center">
        <div class="card px-1 py-4" style="margin-top: 60px">
          <div class="card-body">
            <!-- <p class="text-center">{{message}}</p> -->
            <h2 class="text-center">NsureHub Policy Change Request Portal</h2>
            <h6 class="information mt-4">To Request a Policy Change, Please Fill
              in your Name, Phone, and Policy Number
              with a Discretion and our Service Department will review within 24
              Hrs.</h6>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Name of Insured:</label>
                  <input class="form-control" type="text" id="name" name="name" [(ngModel)]="name">
                  <span *ngIf="nameRequiredValidationMessage"
                    style="color: red">{{nameRequiredValidationMessage}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Policy Number:</label>
                  <input class="form-control" type="text" id="policy" name="policy" [(ngModel)]="policy">
                  <span *ngIf="policyRequiredValidationMessage"
                    style="color: red">{{policyRequiredValidationMessage}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Insurance Company:</label>
                  <input class="form-control" type="text" id="company" name="company" [(ngModel)]="company">
                  <span *ngIf="companyRequiredValidationMessage"
                    style="color: red">{{companyRequiredValidationMessage}}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label>Phone Number:</label>
                  <input class="form-control" type="text" id="phone" name="phone" [(ngModel)]="phone">
                  <span *ngIf="phoneRequiredValidationMessage"
                    style="color: red">{{phoneRequiredValidationMessage}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Description of Requested Policy Change:</label>
                <div class="input-group">
                  <textarea type="text" class="form-control_1" cols="100" id="description" name="description"
                    [(ngModel)]="description"> </textarea>
                </div>
                <span *ngIf="descriptionRequiredValidationMessage"
                  style="color: red">{{descriptionRequiredValidationMessage}}</span>
              </div>
            </div>
          </div>
          <div class="text-center">
            <p>{{message}}</p>
          </div>
          <div class="termsText">
            <span>By hitting Submit you are requesting a policy change. <label *ngIf="!showRemainingTC"
                (click)="showRemainingTC = !showRemainingTC" class="seemoreLabel">See
                more...</label><span class="remainingTC" *ngIf="showRemainingTC">* This change request is not
                binding nor confirmed until approved,
                authorized and confirmed by your insurance company.</span><label *ngIf="showRemainingTC"
                (click)="showRemainingTC = !showRemainingTC" class="seemoreLabel">See
                less...</label>
            </span>
          </div>
          <br>
          <div class="text-center">
            <button class="button-fill" type="submit" name="submit">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<app-footer></app-footer>