import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-kick-out-screen',
  templateUrl: './kick-out-screen.component.html',
  styleUrls: ['./kick-out-screen.component.scss']
})
export class KickOutScreenComponent implements OnInit {

  isBindQuoteKickOff: boolean = false;
  isPaymentError: boolean = false;

  constructor(private dialogRef: MatDialogRef<KickOutScreenComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("errordata=>", data);
    if (data.source?.toLowerCase().includes("bind"))
      this.isBindQuoteKickOff = true;
    if(data.source?.toLowerCase().includes("payment") && data.source?.toLowerCase().includes("failed"))
      this.isPaymentError = true;
  }

  ngOnInit(): void {
  }

  closePopup() {
    this.dialogRef.close();
  }

}
