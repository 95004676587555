import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-quote',
  templateUrl: './get-quote.component.html',
  styleUrls: ['./get-quote.component.scss']
})
export class GetQuoteComponent implements OnInit {

  showRemainingTC: boolean = false;
  termsChecked: boolean = false;

  checkboxChanges(values: any): void {
    if (values.currentTarget.checked) {
      this.termsChecked = true;
    } else {
      this.termsChecked = false;
    }

  }

  constructor() { }
  message = "";
  submitValue = "Send";
  fname = "";
  lname = "";
  email = "";
  phone = "";
  address = "";
  state = "";
  zipcode = "";

  ngOnInit(): void {

  }

  
  submit() {
    // debugger;
    let url = "https://script.google.com/macros/s/AKfycbykpCeGbO0sJN_KgwGy84YTeAHk-oDSWPMh7eI7u01cVAMnm8CTtJJNWchtY3DsXz26/exec";
    var formData = new FormData();
    formData.append("fname", this.fname);
    formData.append("lname", this.lname);
    formData.append("email", this.email);
    formData.append("phone", this.phone);
    formData.append("address", this.address);
    formData.append("state", this.state);
    formData.append("zipcode", this.zipcode);
    this.submitValue = "submitting...";

    fetch(url, {
      method: "POST",
      body: formData
    })
      .then(res => res.text())

      .then(data => {
        this.message = data;
        this.submitValue = "submit"
        //  formData.clear(form);

      })

    this.fname = "";
    this.lname = "";
    this.email = "";
    this.phone = "";
    this.address = ""
    this.state = ""
    this.zipcode = ""
    this.submitValue = "submit"

  }
}



