import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor( private dialogRef:MatDialogRef<PrivacyPolicyComponent>) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.dialogRef.close();
  }

}
