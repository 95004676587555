<app-header></app-header>
<section class="nsurehub-section" id="loss-prevention-main">
  <div class="loss-prevention-main_content">
    <h1>Geared Towards Loss Prevention</h1>
    <p> The biggest cause for home insurance claims is acts of God, with the top
      five claims for insured homes including damage caused by wind and hail,
      water, frost, and fire, according to data from the Insurance Information
      Institute. While we can’t
      necessarily control Mother Nature, there are ways to mitigate some of the
      damage and, ultimately, prevent claims.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock_1">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" *ngIf="false"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>

<section class="nsurehub-section" id="claim-mistake-corevalues">
  <h2>These Are The Worst Home Insurance Claim Mistakes</h2>
  <div class="coreValuesList">
    <div class="coreValue">
      <h1>Failing to Read Your Policy</h1>
    </div>
    <div class="coreValue">
      <h1>Not Having the Right Coverage</h1>
    </div>
    <div class="coreValue">
      <h1>Not Having a Home Inventory</h1>
    </div>
    <div class="coreValue">
      <h1>Failing to Maintain Your Property</h1>
    </div>
    <div class="coreValue">
      <h1>Poor Communication</h1>
    </div>
    <div class="coreValue">
      <h1>Waiting</h1>
    </div>
    <div class="coreValue">
      <h1>Trying to Tackle a Big Claim Alone</h1>
    </div>
    <div class="coreValue">
      <h1>Not Documenting the Damage</h1>
    </div>
    <div class="coreValue">
      <h1>Cleaning Up Too Fast</h1>
    </div>
    <div class="coreValue">
      <h1>Filing Too Many Claims</h1>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" *ngIf="false"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="insurance-claims">
  <h2>6 Tips to Help Clients Prevent Homeowners Insurance Claims</h2>
  <div class="visionBox">
    <h3>Trim the trees</h3>
    <p>A tree next to the home can be aesthetically pleasing, but it can also do
      serious damage.
      While getting it removed altogether can be considered, trimming any
      branches that are touching the home or near power lines is a must.
      Getting rid of dead branches helps a tree grow stronger and healthier.
    </p>
  </div>
  <div class="visionBox" style="margin:1% ;">
    <h3>Bring in the reinforcements</h3>
    <p>NsureHub Insurance shows that there has been an average increase of 12%
      in claims related to hail damage since 2013. Having shutters on windows is
      important to protect them, especially for those who live in an area prone
      to hailstorms.
      Have homeowners make sure storm shutters are securely fastened and in good
      shape.
    </p>
  </div>
  <div class="visionBox">
    <h3>Get smart with technology</h3>
    <p>A small leak in the pipes can go from a drip to a disaster before you
      know it. Thankfully with today’s technology, there are devices to help
      stop water damage before it starts. For just a few hundred dollars,
      devices allow homeowners to not only monitor the usage of water within the
      house,
      but also automatically shut off a home’s water supply when the device
      detects a change in water pressure caused by a leak.
    </p>
  </div>
  <div class="visionBox" style="margin:1% ;">
    <h3>Let the water flow</h3>
    <p>During frigid months, homeowners can be reminded to let faucets served by
      exposed pipes to trickle. While it may increase the water bill slightly,
      the running water can help prevent pipes from freezing and ultimately
      bursting.
    </p>
  </div>
  <div class="visionBox" style="margin:0% ;">
    <h3>Install and upkeep fire alarms</h3>
    <p>Sensitive alarms, such as those triggered by cooking, can result in
      homeowners removing the batteries from their smoke alarms or dismantling
      them altogether. This can have deadly consequences in the event of a home
      fire, so regularly remind homeowners to install at least one alarm on
      every level of the home, near all sleeping areas and inside each bedroom.
      Testing them once a month is a great way to engage with homeowners and
      prevent home fire claims.
    </p>
  </div>
  <div class="visionBox" style="margin:1% ;">
    <h3>Consider sprinklers</h3>
    <p>The stats do not lie when it comes to just how important a sprinkler
      system is. According to the National Fire Protection Association’s
      Sprinkler Initiative, sprinklers lower the home fire death rate by 81% and
      reduce the average property loss rate by 71%. Less damage to the home
      means lower and fewer claims.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="water-heater-section">
  <h2>Hot Water Heaters</h2>
  <p>Did you ever wonder if your hot water heater requires routine maintenance?
    Most people don’t think much about their unit until it develops a leak or
    there is no hot water. Whether it’s a standard residential heater or a
    commercial hot water supply boiler, they need regular maintenance to operate
    efficiently and trouble-free.
  </p>
  <br>
  <img src="../../assets/images/loss-prevention/hotwater.png" alt="NsureHub">
  <p><b>Here are helpful tips to make sure your hot water heater is maintained properly:</b></p>
  <div class="water-heater-visionBox">
    <ol>
      <li>Set the water heater temperature no higher than 120°F for maximum
        efficiency. There is a thermostat dial or adjustment screw on the tank
        that can be manually adjusted. For every 10°F the temperature is
        lowered, energy costs can be reduced by 5%.</li>

      <li>The pressure relief valve is an important safety device that protects
        the tank from developing excessive internal pressures and temperatures
        that could create a dangerous condition. This safety device should be
        tested annually by placing a bucket underneath its drainpipe, and
        manually lifting the valve’s test lever until water flows out. If water
        is still leaking after releasing the test lever, replace the valve. This
        test should be done with the heater’s cold water supply valve closed and
        the power off.</li>

      <li>Also annually, drain the tank to wash out built-up sediment. Connect a
        garden hose to the bottom drain valve and open the valve, draining the
        water into a bucket until clear water runs out.</li>

      <li>Check the anode rod every three years. This rod is designed to
        disintegrate over time as it attracts the corrosive elements in the
        water. If it is extensively corroded or if it is completely covered in
        minerals, replace it. Power should be shut off and about five gallons
        should be drained from the tank before removing the anode.</li>

      <li>Wrapping the water heater in an insulating blanket will help enhance
        performance and decrease heat loss.</li>

      <li>Give it space – be sure to maintain two feet of clearance around the
        heater and never place storage items close to the unit or on top of it.</li>


      <li>Use the water heater’s vacation setting when leaving town or turn the
        thermostat down to its lowest setting.
      </li>
    </ol>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill" *ngIf="false"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="about-footer">
  <div class="about-footer_content">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>