import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-change',
  templateUrl: './policy-change.component.html',
  styleUrls: ['./policy-change.component.scss']
})
export class PolicyChangeComponent implements OnInit {

  showRemainingTC: boolean = false;

  constructor() { }
  message = "";
  submitValue = "Send";
  name = "";
  policy = "";
  company = "";
  phone = "";
  description = "";
  nameRequiredValidationMessage = "";
  policyRequiredValidationMessage = "";
  companyRequiredValidationMessage = "";
  phoneRequiredValidationMessage = "";
  descriptionRequiredValidationMessage = "";

  ngOnInit(): void {
  }
  submit() {
    debugger;

    var isFormValid = true;
    if (!this.name) {
      this.nameRequiredValidationMessage = "*Name of Insured is Required";
      isFormValid = false;
    }

    if (!this.policy) {
      this.policyRequiredValidationMessage = "*Policy Number is Required";
      isFormValid = false;
    }

    if (!this.company) {
      this.companyRequiredValidationMessage = "*Insurance Company is Required";
      isFormValid = false;
    }

    if (!this.phone) {
      this.phoneRequiredValidationMessage = "*Phone Number is Required";
      isFormValid = false;
    }
    if (!this.description) {
      this.descriptionRequiredValidationMessage = "*Description is Required";
      isFormValid = false;
    }

    if (!isFormValid)
      return;

    this.nameRequiredValidationMessage = "";
    this.policyRequiredValidationMessage = "";
    this.companyRequiredValidationMessage = "";
    this.phoneRequiredValidationMessage = "";
    this.descriptionRequiredValidationMessage = "";

    let url = "https://script.google.com/macros/s/AKfycbwhpmSsvfSrAJ83TGjahvkHNgCbY_zqxfyKtEukYb2A01oZ1NqXu4Zx2Dccvh9I1Pzu/exec";
    var formData = new FormData();
    formData.append("name", this.name);

    formData.append("policy", this.policy);
    formData.append("company", this.company);
    formData.append("phone", this.phone);
    formData.append("description", this.description);
    // form.addEventListener("submit", (e) => {
    // e.preventDefault();
    this.submitValue = "submitting...";

    fetch(url, {
      method: "POST",
      body: formData
    })
      .then(res => res.text())

      .then(data => {
        this.message = data;
        this.submitValue = "submit"

      })

    this.name = "";
    this.policy = "";
    this.company = "";
    this.phone = "";
    this.description = ""

  }

}




