export const environment = {
  production: false,
  baseUrl: "https://ondemanddev.nsurehub.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://ondemanddev.nsurehub.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "DexRLzGjnDJIyO7j8vef2N3Znqyt13eW",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://ondemanddev.nsurehub.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentDev = {
  production: false,
  baseUrl: "https://ondemanddev.nsurehub.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://ondemanddev.nsurehub.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "DexRLzGjnDJIyO7j8vef2N3Znqyt13eW",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://ondemanddev.nsurehub.com/paymentProcessAeg",

  CApaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://devbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentQA = {
  production: false,
  baseUrl: "https://qansurehub.middleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://qansurehub.middleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "d0999ca866254fc9b334g16207916e5d",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://qabilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://qansurehub.middleware.safepointdc.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://qabilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://qabilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentTT = {
  production: false,
  baseUrl: "https://ttnsurehub.middleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://ttnsurehub.middleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "YxTFkmL4zPDJ3H8Q7vef1PsZnqrt12vW",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://ttbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://ttnsurehub.middleware.safepointdc.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://ttbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://ttbilling.middleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentUAT = {
  production: false,
  baseUrl: "https://uat.nsurehub.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://uat.nsurehub.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "KxERLzJjnDJIYO7j67vKf2N3Zbtgt14e",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://uatbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://uat.nsurehub.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://uatbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://uatbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentPERF = {
  production: false,
  baseUrl: "https://perfnsurehub.preprodmiddleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://perfnsurehub.preprodmiddleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "Sh9FpQZx8cNpA5Yr2o5GdVkL6tUbWs7j",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://perfbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://perfnsurehub.preprodmiddleware.safepointdc.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://perfbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://perfbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentDEBUG = {
  production: false,
  baseUrl: "https://debugnsurehub.preprodmiddleware.safepointdc.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://debugnsurehub.preprodmiddleware.safepointdc.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "Dm5KnVr8xOs1Pq5HtFb4WcYh7ZXuGah7",

  SFpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",
  CApaymentURL: "https://batinternet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "3Tn0LH94ZMH+JGuVwIWGcoNVIpSaYjQtIySG1LAgIMA=",
  MNpaymentURL: "https://batinternet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "gV2JebyzKSr0KKyHO/mTBtNkXA4gq1YOynMAoHUKr18=",

  SFpaymentResponseUrl: "https://debugbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://debugnsurehub.preprodmiddleware.safepointdc.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://debugbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://debugbilling.preprodmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};

export const environmentPROD = {
  production: true,
  baseUrl: "https://www.nsurehub.com/api/Nsurehub/",
  versiumAPIKEY: "f39f0806-10bd-4002-8e97-e1c751575963",
  googleKEY: "QUl6YVN5QmQ1LTBvd3F5dEgwOTk0S09pRkhxakIyNS16cVBZYWdz",
  datafinderURL: "https://api.versium.com/v2",
  infutorURL: "https://xml.yourdatadelivery.com",
  dcAPI: "https://www.nsurehub.com/api/Nsurehub/",
  gicAPI: "https://api.gic.org",
  gicUserName: "TmlzaHRoYS5zb25pQGFnZ25lLmNvbQ==",
  gicPassword: "QWdnbmVAMTIz",
  debugEnabled: false,
  XApiKey: "Pw7eR1TdBU9LoXc9UfA3zGpJm6N8hIWE",

  SFpaymentURL: "https://internet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  SFpaymentSecretKey: "Cn0vEONzWC88y/LPSAcIo5hBN9xid/vXKke2vhGfs9s=",
  CApaymentURL: "https://internet.speedpay.com/api/cajunuw/sso?cid=CajunUWSSO",
  CApaymentSecretKey: "D9m0NyBO2PlIEiQsgSzNYRzxdxuy64B0gBHLyUE85Sk=",
  MNpaymentURL: "https://internet.speedpay.com/api/safepoint/sso?cid=SafepointSSO",
  MNpaymentSecretKey: "Cn0vEONzWC88y/LPSAcIo5hBN9xid/vXKke2vhGfs9s=",

  SFpaymentResponseUrl: "https://billing.pmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubSafePoint",
  paymentStatusPage: "Portal|https://www.nsurehub.com/paymentProcessAeg",
  CApaymentResponseUrl: "https://billing.pmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubCajun",
  MNpaymentResponseUrl: "https://billing.pmiddleware.safepointdc.com/api/Billing/ASPX?Carrier=NsurehubManatee"
};