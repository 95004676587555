import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { AnimalLibilityConfirmComponent } from '../animal-libility-confirm/animal-libility-confirm.component';

@Component({
  selector: 'app-add-smiam-liability',
  templateUrl: './add-smiam-liability.component.html',
  styleUrls: ['./add-smiam-liability.component.scss']
})
export class AddSmiamLiabilityComponent implements OnInit {

  constructor(public dialog: MatDialog,public appService: AppService, public dialogRef: MatDialogRef<AddSmiamLiabilityComponent>) { }

  ngOnInit(): void {
  }
  goToNextStep() {

    let dialogRef = this.dialog.open(AnimalLibilityConfirmComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.dialogRef.close(true);
      }
      
    });
  }
  cancel(){
    this.dialogRef.close(false);
  }

}
