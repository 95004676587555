<app-header></app-header>
<section class="nsurehub-section" id="credit-repair-main">
  <div class="credit-repair-main_content">
    <h1>NsureHub Home Self-Inspection Program</h1>
    <p>NsureHub makes it easy to bind your High-Value Home with our Self-Inspection Application.</p>
  </div>
</section>
<section class="nsurehub-section" id="credit-repair-rates">
  <h2>Added Convenience. Increased Comfort.</h2>
  <p>We know that finding the time for a home inspection can be tough. To help Keep the Promise® and continue to bring
    you the best insurance coverage, NsureHub is now offering Home Self-Inspections for select policyholders.
  </p>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock_1">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="home-discount-corevalues">
  <h2>Some of the benefits of our new Home Self-Inspection Program include:</h2>
  <div class="coreValuesList">
    <div class="coreValue">
      <h1>Convenience</h1>
      <h6>Skip scheduling an appointment, you can submit the photos on your own time.</h6>
    </div>
    <div class="coreValue">
      <h1>Comfort</h1>
      <h6>No strangers entering your home.</h6>
    </div>
    <div class="coreValue">
      <h1>Ease</h1>
      <h6>With straightforward instructions, it’s never been easier to complete a home inspection.</h6>
    </div>
    <div class="coreValue">
      <h1>Improved Claims</h1>
      <h6>With video recording, claims handling can be done with more detail.</h6>
    </div>
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="credit-repair-wherewedoBusiness">
  <div class="wherewedoBusiness-right">
    <h2>It’s as Easy as Sending a Photo</h2>
    <p>It’s simple. All you need is a smartphone or tablet, and to follow a few easy steps for photographing the
      pertinent parts of your home. Things like the exterior, air handling units, electrical panel, and plumbing will
      all be covered in your home inspection. After that, you’ll record a short walkthrough of your main living area.
      Your photos and videos will instantly be uploaded to NsureHub Insurance for review. Quick. Simple. Easy.
      Once your NsureHub Insurance policy is written, you will receive instructions directly from NsureHub for your home
      inspection.
    </p>
  </div>
  <div class="wherewedoBusiness-left">
    <img src="../../assets/images/self-inspection/download.jpg" alt="NsureHub" />
  </div>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="about-missionVision">
  <div class="visionBox">
    <h3>What are Self Inspections?</h3>
    <p>Self inspections are methods of allowing the insured to carry out their own inspections, sending all the required
      information directly to their carrier. This allows many benefits for efficiency, communications and savings on the
      insured's overall costs.</p>

    <p>Self inspection technology allows the policyholder to take pictures of smoke detectors, sinks, home exteriors and
      other insurable assets. This allows the insured to directly upload images to a cloud-based file location and
      attach to a policy within cloud insurance software. Underwriters are able to review the data collected from the
      app for approval.
    </p>
  </div>
</section>
<section class="nsurehub-section" id="credit-repair-rates">
  <h2>How the Self-Inspection Works</h2>
  <section class="nsurehub-section" id="about-video">
    <video controls poster="../../assets/images/self-inspection/self-inspection.png">
      <source src="../../assets/videos/self-inspection.mp4" type="video/mp4">
      Your browser does not support the video tag.
      <track label="English" kind="subtitles" srclang="en" src="REV_TRANSCRIPTION_V3.vtt" default>
    </video>
  </section>
  <br>
</section>
<section class="nsurehub-section" id="benefit-chart-section">
  <div class="benefitChartCTABlock">
    <h2>Make the Move with NsureHub today!</h2>
    <button class="button-fill"
      onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';">
      Get a Quote
    </button>
  </div>
</section>
<section class="nsurehub-section" id="about-footer">
  <div class="about-footer_content">
    <h3>Fulfill your responsibility to yourself & your loved ones.</h3>
  </div>
</section>
<app-footer></app-footer>